/* ===============================================
# Sassのミックスインを記述するファイルです
=============================================== */
@use './variables' as *;

/* ===============================================
# メディアクエリ
=============================================== */
$breakpoint: (
  sm: "screen and (max-width: 520px)",
  lg: "screen and (max-width: 960px)",
);

@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}

/* ===============================================
# z-index
=============================================== */
$z-index: (
  header: 1000,
  hamburger: 700,
  mvBackground: 0,
  mvLeft: 500,
  mvRight: 400,
  background: 0,
  text: 100,
  title: 500,
  giji: -1
);

@mixin z-index($context) {
  z-index: map-get($z-index, $context)
}

/* ===============================================
# line-height
=============================================== */
@mixin l_height($height , $pixels) {
	line-height: calc($height / $pixels);
}

/* ===============================================
# letter-spacing
=============================================== */
@mixin l_spacing($spacing) {
	letter-spacing: calc($spacing / 1000 ) + em;
}
@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.c-animation-init{
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	opacity: 0;
}

.c-animation-line{
	animation-name: lineExtendLg;
	@include mq(lg){
		animation-name: lineExtendSm;
	}
}

@keyframes lineExtendLg {
	0%{
		transform: scaleX(0);
	}
	100%{
		transform: scaleX(100%);
	}
}

@keyframes lineExtendSm {
	0%{
		transform: scaleY(0);
	}
	100%{
		transform: scaleY(100%);
	}
}
@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.c-header-right-entry-button{
	width: 17.2rem;
	height: 5.4rem;
	background-color: $cWhite;
	border-radius: 2.7rem;
	border: 1px solid $cRed;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	position: relative;
	overflow: hidden;
	&::after{
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $cRed;
		z-index: 0;
		left: 0;
		transition-duration: 0.3s;
	}
	&:hover::after{
		left: 100%;
	}
	&:hover .c-header-right-entry-button__inner-en{
		color: $cRed;
	}
	&:hover .c-header-right-entry-button__inner-ja{
		color: $cRed;
	}
	&__inner-en{
		display: block;
		color: $cWhite;
		font-size: $fs-18;
		font-family: $ff-termina;
		font-weight: $fw-bold;
		@include l-spacing(100);
		position: relative;
		z-index: 1;
		transition-duration: 0.3s;
	}
	&__inner-ja{
		font-size: $fs-10;
		color: $cWhite;
		font-family: $ff-yu-go;
		font-weight: $fw-bold;
		position: relative;
		z-index: 1;
		transition-duration: 0.3s;
	}
}

.c-header-right-hamburger-button{
	width: 5.5rem;
	height: 5.5rem;
	border-radius: 100%;
	background-color: $cWineRed;
	border: 1px solid $cWineRed;
	align-items: center;
	justify-content: center;
	display: none;
	flex-direction: column;
	transition-duration: 0.5s;
	transition-property: transform,background-color;
	@include mq(lg){
		display: flex;
	}
	&-line{
		display: block;
		width: 2.239rem;
		height: 1px;
		background-color: $cWhite;
		position: relative;
		transition-duration: 0.5s;
		transition-property: transform,background-color,opacity,top,bottom;

	}
	& .__top{
		top: -0.59rem;
	}
	& .__bottom{
		bottom: -0.59rem;
	}
}

.c-mv-textarea-entry{
	width: calc(100vw * calc(315.32 / 1280));//31.532rem;
	height: calc(100vw * calc(56.95 / 1280));//5.695rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $cRed;
	border-radius: calc(calc(100vw * calc(56.95 / 1280)) / 2);
	overflow: hidden;
	background-color: $cWhite;
	margin-bottom: calc(100vh * calc(99.2 / 800));
	box-shadow: 4px 4px 3px rgba(52,52,52,0.16);
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	opacity: 0;
	@include mq(lg){
		display: none;
	}
	&::after{
		content: "";
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: $cRed;
		z-index: 0;
		transition-duration: 0.3s;
	}
	&:hover::after{
		left: 100%;
	}
	&__inner{
		width: max-content;
		font-size: calc(100vw * calc(19 / 1280));//$fs-19;
		font-family: $ff-yu-go;
		font-weight: $fw-bold;
		color: $cWhite;
		position: relative;
		z-index: 1;
		transition-duration: 0.3s;
	}
	&:hover .c-mv-textarea-entry__inner{
		color: $cRed;
	}
}

.c-entryarea-button{
	width: 38.6rem;
	height: 5.1rem;
	background-color: $cWineRed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: calc(5.1rem / 2);
	margin: 0 auto;
	border: 1px solid $cWhite;
	position: relative;
	overflow: hidden;
	@include z-index(text);
	&::after{
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: $cWhite;
		@include z-index(background);
		transition-duration: 0.5s;
		transition-property: transform;
	}
	&:hover::after{
		transform: translateX(100%);
	}
	&:hover .c-entryarea-button__inner-en{
		color: $cWhite;
	}
	&:hover .c-entryarea-button__inner-ja{
		color: $cWhite;
	}
	@include mq(sm){
		width: calc(100vw * calc(304 / 375));
		height: 5.5rem;
	}
	&__inner-en{
		font-size: $fs-18;
		font-family: $ff-termina;
		font-weight: $fw-bold;
		@include l-spacing(100);
		@include l-height(20 , 18);
		color: $cWineRed;
		position: relative;
		@include z-index(text);
		transition-duration: 0.5s;
		transition-property: color;
	}
	&__inner-ja{
		font-size: $fs-10;
		font-family: $ff-yu-go;
		font-weight: $fw-bold;
		color: $cWineRed;
		@include l-height(15 , 10);
		position: relative;
		@include z-index(text);
		transition-duration: 0.5s;
		transition-property: color;
	}
}

.c-mv-sm-entry-button{
	width: 31.532rem;
	height: 5.695rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $cRed;
	border-radius: calc(calc(100vw * calc(56.95 / 375)) / 2);
	overflow: hidden;
	background-color: $cWhite;
	box-shadow: 4px 4px 3px rgba(52,52,52,0.16);
	z-index: 500;
	display: none;
	margin-top: 1.9rem;
	@include mq(lg){
		display: flex;
	}
	@include mq(sm){
		width: calc(100vw * calc(1200 / 1280));
	}
	&::after{
		content: "";
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		background-color: $cRed;
		z-index: 0;
		transition-duration: 0.3s;
	}
	&:hover::after{
		left: 100%;
	}
	&__inner{
		width: max-content;
		font-size: $fs-19;
		font-family: $ff-yu-go;
		font-weight: $fw-bold;
		color: $cWhite;
		position: relative;
		z-index: 1;
		transition-duration: 0.3s;
	}
	&:hover .c-mv-sm-entry-button__inner{
		color: $cRed;
	}
}

.c-hamburger-entry-button{
	width: 100%;
	height: auto;
	flex-grow: 1;
	background-color: $cWhite;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 1px solid $cWineRed;
	position: relative;
	&::after{
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $cWineRed;
		@include z-index(background);
		transition-duration: 0.5s;
		transition-property: transform;
	}
	&:hover::after{
		transform: translateX(100%);
	}
	&:hover .c-hamburger-entry-button__inner-en{
		color: $cWineRed;
	}
	&:hover .c-hamburger-entry-button__inner-ja{
		color: $cWineRed;
	}
	&__inner-en{
		font-size: $fs-30;
		font-family: $ff-mont;
		font-weight: $fw-mid;
		color: $cWhite;
		@include l-spacing(200);
		padding-top: 4.2rem;
		transition-duration: 0.5s;
		transition-property: color;
		position: relative;
		@include z-index(text);
	}
	&__inner-ja{
		font-size: $fs-16;
		font-family: $ff-yu-go;
		font-weight: $fw-bold;
		color: $cWhite;
		@include l-spacing(100);
		padding-top: 2rem;
		transition-duration: 0.5s;
		transition-property: color;
		position: relative;
		@include z-index(text);
		padding-bottom: 4.2rem;
	}
}
 .c-form-confirm{
	width: 38.6rem;
	height: 5.1rem;
	background-color: $cWhite;
	border: 1px solid $cWineRed;
	border-radius: calc(5.1rem / 2);
	position: relative;
	overflow: hidden;
	left: 50%;
	transform: translateX(-50%);
	@include mq(sm){
		width: calc(100vw * calc(335 / 375));
	}
	&::after{
		content: "";
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: $cWineRed;
		@include z-index(background);
		transition-duration: 0.5s;
		transition-property: transform;
	}
	&:hover::after{
		transform: translateX(100%);
	}
	&:hover .c-form-confirm__inner{
		color: $cWineRed;
	}
	&__inner{
		text-align: center;
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		color: $cWhite;
		@include z-index(text);
		position: relative;
		transition-duration: 0.5s;
		transition-property: color;
	}
 }

 .c-thanks-return-top{
	width: 38.6rem;
	height: 5.1rem;
	border: 1px solid $cWineRed;
	border-radius: calc(5.1rem / 2);
	background-color: $cWhite;
	position: relative;
	overflow: hidden;
	margin-top: 5rem;
	@include z-index(text);
	@include mq(sm){
		width: calc(100vw * calc(335 / 375));
	}
	&::after{
		content: "";
		width: 100%;
		height: 100%;
		background-color: $cWineRed;
		position: absolute;
		top: 0;
		transition-duration: 0.5s;
		transition-property: transform;
	}
	&:hover::after{
		transform: translateX(100%);
	}
	&:hover .c-thanks-return-top__inner{
		color: $cWineRed;
	}
	&__inner{
		text-align: center;
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		color: $cWhite;
		position: relative;
		transition-duration: 0.5s;
		transition-property: color;
		@include z-index(text);
		&__arrow{
			padding-right: 1.5rem;
			font-size: 2rem;
		}
	}
 }


@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.p-form{
	width: 100%;
	background-color: $cLightBeige;
	padding-bottom: 10rem;
	&-page-show{
		font-size: $fs-16;
		font-weight: $fw-mid;
		font-family: $ff-yu-go;
		@include l-spacing(100);
		color: $cBlueBlack;
		padding-top: 15.6rem;
		padding-left: calc(100vw * calc(107 / 1280));
		padding-bottom: 5.3rem;
		@include mq(lg){
			padding-top: 8.9rem;
			padding-bottom: 10rem;
		}
		&__arrow{
			color: $cBoldBlack;
			padding-left: 1.07rem;
			padding-right: 1.07rem;
		}
	}
	&__title-en{
		font-size: $fs-35;
		font-weight: $fw-bold;
		font-family: $ff-oswald;
		@include l-spacing(100);
		@include l-height(33 , 35);
		color: $cWineRed;text-align: center;
		padding-bottom: 0.9rem;
	}
	&__title-ja{
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		@include l-spacing(100);
		@include l-height(33 , 16);
		text-align: center;
		padding-bottom: 5.05rem;
	}
	&-stepsbox{
		width: max-content;
		padding-right: 2.3rem;
		padding-left: 2.3rem;
		padding-top: 2.65rem;
		padding-bottom: 2.65rem;
		border-top: 1px solid $cGray;
		border-bottom: 1px solid $cGray;
		margin: 0 auto;
		margin-bottom: 5.15rem;
		&__steps{
			font-size: $fs-16;
			font-weight: $fw-bold;
			font-family: $ff-yu-go;
			@include l-spacing(100);
			& .--arrow{
				padding-right: 3rem;
				padding-left: 3rem;
			}
			& .--red{
				color: $cWineRed;
			}
		}
	}
	&-explain{
		font-size: $fs-16;
		font-weight: $fw-mid;
		font-family: $ff-yu-go;
		@include l-spacing(100);
		@include l-height(33 , 16);
		color: $cBlueBlack;
		text-align: center;
		padding-bottom: 5rem;
		width: calc(100vw * calc(335 / 375));
		margin: 0 auto;
		& .--br-sl-use{
			display: none;
			@include mq(lg){
				display: block;
			}
		}
	}
	&-container{
		width: 77.5rem;
		margin: 0 auto;
		@include mq(lg){
			width: calc(100vw * calc(335 / 375));
		}
	}
	&-unit{
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-top:1px solid $cGray;
		padding-top: 3rem;
		padding-bottom: 3rem;
		align-items: center;
		@include mq(lg){
			flex-direction: column;
			align-items: flex-start;
		}
		&-titles-container{
			display: flex;
			align-items: center;
			padding-right: 4.1rem;
			width: 27.8rem;
			box-sizing: content-box;
			justify-content: space-between;
			@include mq(lg){
				justify-content: flex-start;
				padding-bottom:  1.85rem;
				padding-right: 0;
			}
		}
		&__title{
			font-size: $fs-16;
			font-weight: $fw-mid;
			font-family: $ff-yu-go;
			@include l-spacing(100);
			color: $cBlueBlack;
			@include mq(lg){
				padding-right: 2rem;
			}
		}
		&__req-or-any{
			font-size: $fs-10;
			font-weight: $fw-bold;
			font-family: $ff-yu-go;
			@include l-spacing(500);
			padding-top: 0.5rem;
			padding-right: 1.6rem;
			padding-bottom: 0.5rem;
			padding-left: 1.6rem;
		}
		& .--required{
			border: 1px solid $cWineRed;
			color: $cWineRed;
		}
		& .--any{
			border: 1px solid $cLightGray;
			color: $cLightGray;
		}
		&-selectbox-container{
			display: flex;
			justify-content: space-between;
			width: 45.6rem;
			@include mq(lg){
				flex-direction: column;
				width: calc(100vw * calc(335 / 375));
				max-width: 33.5rem;
				margin: 0 auto;
			}
		}
		&-selectbox{
			display: flex;
			align-items: center;
			@media (max-width:370px){
				justify-content: center;
			}
			&__select{
				position: relative;
				display: flex;
				justify-content: space-around;
				width: max-content;
				font-family: $ff-yu-go;
				&::after{
					content: "";
					position: absolute;
					border-top: 0.9rem solid $cBoldBlack;
					border-left: 0.5rem solid transparent;
					border-right: 0.5rem solid transparent;
					top:50%;
					transform: translateY(-50%);
					right: 1.8rem;
				}
			}
			& .--year{
				@include mq(lg){
					// max-width: 31.2rem;
					// width: calc(100vw * calc(312 / 375));
					width: max-content;
				}
			}
			& .--month{
				@include mq(lg){
					// max-width: 13.4rem;
					// width: calc(100vw * calc(134 / 375));
					width: max-content;
				}
			}
			& .--day{
				@include mq(lg){
					// max-width: 13.4rem;
					// width: calc(100vw * calc(134 / 375));
					width: max-content;
				}
			}
			&-day-month{
				display: flex;
				justify-content: space-around;
				flex-grow: 1;
				@include mq(lg){
					justify-content: space-between;
					padding-top: 1rem;
				}
				@media (max-width:375px){
					justify-content: space-around;
				}
			}
			&-days{
				font-size: $fs-16;
				font-weight: $fw-mid;
				font-family: $ff-yu-go;
				color: $cBoldBlack;
				padding-left: 0.8rem;
			}
		}
		&-bottom-wrapper{
			display: flex;
			flex-direction: column;
			padding-top: 2.8rem;
			@include mq(lg){
				margin: 0 auto;
			}
			&__inner{
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;
				padding-top: 1.6rem;
				@include mq(lg){
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
		&-bottom-num{
			font-size: $fs-16;
			font-weight: $fw-mid;
			font-family: $ff-yu-go;
			color: $cBlueBlack;
			@include l-spacing(100);
			width: 27.8rem;
			text-align: center;
			@include mq(lg){
				width: max-content;
				padding-bottom: 2rem;
			}
		}
	}
	& .--unit-bottom{
		flex-direction: column;
		align-items: flex-start;
		border-bottom:1px solid $cGray;
		margin-bottom: 5rem;
		&-titles{
			width: auto;
		}
	}
	&-agree{
		display: flex;
		margin: 0 auto;
		width: max-content;
		padding-bottom: 5rem;
		align-items: center;
		&__text{
			font-size: $fs-16;
			font-weight: $fw-mid;
			font-family: $ff-yu-go;
			color: $cboldGray;
			width: max-content;
			padding-left: 1rem;
			@include l-height(30 , 16);
			& .--br-sl-use{
				display: none;
				@include mq(sm){
					display: block;
				}
			}
		}
		&-check{
			display: none;
		}
		&-checkbox{
			width: 1.9rem;
			height: 1.9rem;
			background-color: $cWhite;
			border: 1px solid $cBlueBlack;
		}
		.checkbox-active{
			position: relative;
			&::after{
				content: "";
				position: absolute;
				width: 20%;
				height: 50%;
				top: 50%;
				left: 10%;
				transform: rotateZ(-45deg);
				background-color: $cWineRed;
			}
			&::before{
				content: "";
				position: absolute;
				width: 20%;
				height: 80%;
				bottom: 0;
				left: 50%;
				transform: rotateZ(45deg);
				background-color: $cWineRed;
			}
		}
	}
	& .--grade-select-unit{
		justify-content: flex-end;
	}
	& .--grade{
		@include mq(lg){
			align-self: center;
		}
	}
}
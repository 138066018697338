@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;


.c-form-unit-input{
	font-size: $fs-16;
	background-color: $cWhite;
	padding-top: 1rem;
	padding-left: 1.6rem;
	padding-bottom: 1rem;
	border: 1px solid $cThinGray;
	width: 45.6rem;
	font-family: $ff-yu-go;
	@include mq(lg){
		width: 100%;
	}
}

.c-form-unit-selectbox__select-inner{
	font-size: $fs-16;
	padding-top: 1rem;
	padding-bottom: 1rem;
	position: relative;
	background-color: $cWhite;
	border: 1px solid $cThinGray;
	@include mq(lg){
	}
}

.--select-year{
	padding-right: 5.2rem;
	padding-left: 5.2rem;
	@include mq(lg){
		padding-right: 13.4rem;
		padding-left: 13.4rem;
		text-align: center;
	}
	@media (max-width:370px){
		padding-left: 10rem;
		padding-right: 10rem;
	}
}

.--select-month{
	padding-right: 4.2rem;
	padding-left: 4.2rem;
	@include mq(lg){
		padding-right: 6.3rem;
		padding-left: 6.3rem;
		text-align: center;
	}
	@media (max-width:370px){
		padding-right: 4rem;
		padding-left: 4rem;
	}
}

.--select-day{
	padding-right: 4.2rem;
	padding-left: 4.2rem;
	@include mq(lg){
		padding-right: 6.3rem;
		padding-left: 6.3rem;
		text-align: center;
	}
	@media (max-width:370px){
		padding-right: 4rem;
		padding-left: 4rem;
	}
}

.--grade-select{
	padding-right: 10rem;
	padding-left: 10rem;
	@include mq(lg){
		align-self: center;
	}
}
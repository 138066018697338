/* ===============================================
# 全ページ共通のスタイルを記述するファイルです
=============================================== */

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    @media (max-width:520px) {
        font-size: 62.5%;
    }
}

img {
    width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

section{
    scroll-margin-top: 10rem;
}

body{
    overflow-x: hidden;
}
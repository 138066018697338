@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.l-footer{
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: $cLightBlack;
	padding-bottom: 1.6rem;
	&-logosarea{
		padding-top: 5.6rem;
		display: flex;
		align-items: center;
		padding-bottom: 1.24rem;
		&-logobox{
			width: 16.5rem;
			margin-right: 2rem;
		}
		&-line{
			position: relative;
			height: 6rem;
			width: 1px;
			margin-right: 2rem;
			background-color: $cWhite;
		}
		&-text{
			color: $cWhite;
			font-family: $ff-yu-go;
			font-weight: $fw-bold;
			font-size: $fs-14;
			@include l-height(19,14);
			@include l_spacing(30);
		}
	}
	&-jump-corporate{
		margin-bottom: 5.67rem;
		font-size: $fs-12;
		font-weight: $fw-mid;
		font-family: $ff-yu-go;
		@include l-height(27 , 12);
		color: $cWhite;
		&-awesome{
			padding-left: 0.31rem;
		}
	}
	&-copyrights{
		font-size: $fs-12;
		font-weight: $fw-mid;
		color: $cWhite;
		font-family: $ff-yu-go;
		@include l-height(27 , 12);
	}
}


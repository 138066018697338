@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.l-hamburger{
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: $cLightBeige;
	@include z-index(hamburger);
	justify-content: space-between;
	transform: translateX(100%);
	transition-duration: 0.5s;
	transition-property: transform;
	transition-timing-function: ease-out;
	overflow-y: scroll;
	&-scroll{
		height: 100%;
		overflow-y: scroll;
	}
	&-container{
		width: 100%;
		height: max-content;
		min-height: 105%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&-nav{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 16.9rem;
		&__item{
			padding-bottom: 7rem;
			&__anchor{
				font-size: $fs-25;
				font-family: $ff-yu-go;
				font-weight: $fw-bold;
				color: $cBoldBlack;
				@include l-spacing(100);
				transition-duration: 0.5s;
				transition-property: color;
				&:hover{
					color: $cWineRed;
				}
			}
		}
	}
}

.testtest{
	width: 100vw;
	height: 100vh;
	background-color: blue;
}
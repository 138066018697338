@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.l-header{
	width: calc(100vw * calc(1200 / 1280));
	height: 9.9rem;
	border-radius: 5rem;
	box-shadow: 0 3px 6px rgba(141, 141, 142, 0.16);
	display: flex;
	justify-content: space-between;
	background-color: $cWhite;
	position: fixed;
	@include z-index(header);
	top: 2.7rem;
	left: calc(100vw * calc(40 / 1280));
	@include mq(lg){
		background-color: transparent;
		box-shadow: none;
		width: calc(100vw * calc(335 / 375));
		height: 5.5rem;
		top: 1.95rem;
		left: calc(100vw * calc(21 / 375));
	}
	&-left{
		display: flex;
		align-items: center;
		margin-left: 6.7rem;
		@media(max-width:1235px) and (min-width:961px){
			margin-left: 1.5rem;
		}
		@include mq(lg){
			margin-left: 0;
		}
		&-logo{
			width: 16.5rem;
			margin-right: 2rem;
			@media(max-width:1235px) and (min-width:960px){
				margin-right: 1.5rem;
			}
			@include mq(lg){
				width: 11.2rem;
				margin-right: 1.37rem;
			}
		}
		&-line{
			position: relative;
			height: 6rem;
			width: 1px;
			margin-right: 2rem;
			background-color: $cLightGray;
			@media(max-width:1235px) and (min-width:960px){
				margin-right: 1.5rem;
			}
			@include mq(lg){
				height: 4.095rem;
				margin-right: 1.37rem;
			}
		}
		&-text{
			color: $cBoldBlack;
			font-family: $ff-yu-go;
			font-weight: $fw-bold;
			font-size: $fs-14;
			@include l-height(19,14);
			@include l_spacing(30);
			@media(max-width:1235px) and (min-width:960px){
				font-size: $fs-12;
			}
			@include mq(lg){
				font-size: $fs-9;
				@include l-height(12 , 9);
			}
		}
	}

	&-right{
		display: flex;
		align-items: center;
		margin-right: 6.7rem;
		@media(max-width:1235px) and (min-width:961px){
			margin-right: 1.5rem;
		}
		@include mq(lg){
			display: none;
			margin-right: 0;
		}
		&-navi__list{
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-item{
				padding-right: calc(100vw * calc(30 / 1280));
				&__anchor{
					color: $cBoldBlack;
					font-family: $ff-yu-go;
					font-weight: $fw-bold;
					font-size: $fs-16;
					position: relative;
					@include l-height(27,16);
					@media (max-width:1235px) and (min-width:960px){
						font-size: $fs-12;
					}
					&::after{
						content: "";
						width: 8px;
						height: 8px;
						background-color: $cRed;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: -12px;
						border-radius: 100%;
						opacity: 0;
					}
					&:hover::after{
						opacity: 1;
					}
				}
			}
		}
	}
}
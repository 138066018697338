@charset "UTF-8";
*,
::before,
::after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

main {
  display: block;
}

p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}

pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

address {
  font-style: inherit;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

svg,
img,
embed,
object,
iframe {
  vertical-align: bottom;
}

button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-align: inherit;
  text-transform: inherit;
}

[type=checkbox] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type=radio] {
  -webkit-appearance: radio;
  appearance: radio;
}

button,
[type=button],
[type=reset],
[type=submit] {
  cursor: pointer;
}

button:disabled,
[type=button]:disabled,
[type=reset]:disabled,
[type=submit]:disabled {
  cursor: default;
}

:-moz-focusring {
  outline: auto;
}

select:disabled {
  opacity: inherit;
}

option {
  padding: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

label[for] {
  cursor: pointer;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[contenteditable]:focus {
  outline: auto;
}

table {
  border-color: inherit;
}

caption {
  text-align: left;
}

td,
th {
  vertical-align: top;
  padding: 0;
}

th {
  text-align: left;
  font-weight: 700;
}

/* ===============================================
# 変数設定用のファイルです
=============================================== */
/* ===============================================
# 色
=============================================== */
/* ===============================================
# フォントサイズ
=============================================== */
/* ===============================================
# Sassのミックスインを記述するファイルです
=============================================== */
/* ===============================================
# メディアクエリ
=============================================== */
/* ===============================================
# z-index
=============================================== */
/* ===============================================
# line-height
=============================================== */
/* ===============================================
# letter-spacing
=============================================== */
/* ===============================================
# 全ページ共通のスタイルを記述するファイルです
=============================================== */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media (max-width: 520px) {
  html {
    font-size: 62.5%;
  }
}

img {
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

section {
  scroll-margin-top: 10rem;
}

body {
  overflow-x: hidden;
}

.l-header {
  width: 93.75vw;
  height: 9.9rem;
  border-radius: 5rem;
  box-shadow: 0 3px 6px rgba(141, 141, 142, 0.16);
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  z-index: 1000;
  top: 2.7rem;
  left: 3.125vw;
}
@media screen and (max-width: 960px) {
  .l-header {
    background-color: transparent;
    box-shadow: none;
    width: 89.3333333333vw;
    height: 5.5rem;
    top: 1.95rem;
    left: 5.6vw;
  }
}
.l-header-left {
  display: flex;
  align-items: center;
  margin-left: 6.7rem;
}
@media (max-width: 1235px) and (min-width: 961px) {
  .l-header-left {
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 960px) {
  .l-header-left {
    margin-left: 0;
  }
}
.l-header-left-logo {
  width: 16.5rem;
  margin-right: 2rem;
}
@media (max-width: 1235px) and (min-width: 960px) {
  .l-header-left-logo {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 960px) {
  .l-header-left-logo {
    width: 11.2rem;
    margin-right: 1.37rem;
  }
}
.l-header-left-line {
  position: relative;
  height: 6rem;
  width: 1px;
  margin-right: 2rem;
  background-color: #B9B9B9;
}
@media (max-width: 1235px) and (min-width: 960px) {
  .l-header-left-line {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 960px) {
  .l-header-left-line {
    height: 4.095rem;
    margin-right: 1.37rem;
  }
}
.l-header-left-text {
  color: #191919;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3571428571;
  letter-spacing: 0.03em;
}
@media (max-width: 1235px) and (min-width: 960px) {
  .l-header-left-text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 960px) {
  .l-header-left-text {
    font-size: 0.9rem;
    line-height: 1.3333333333;
  }
}
.l-header-right {
  display: flex;
  align-items: center;
  margin-right: 6.7rem;
}
@media (max-width: 1235px) and (min-width: 961px) {
  .l-header-right {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 960px) {
  .l-header-right {
    display: none;
    margin-right: 0;
  }
}
.l-header-right-navi__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.l-header-right-navi__list-item {
  padding-right: 2.34375vw;
}
.l-header-right-navi__list-item__anchor {
  color: #191919;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  position: relative;
  line-height: 1.6875;
}
@media (max-width: 1235px) and (min-width: 960px) {
  .l-header-right-navi__list-item__anchor {
    font-size: 1.2rem;
  }
}
.l-header-right-navi__list-item__anchor::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #C81422;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
  border-radius: 100%;
  opacity: 0;
}
.l-header-right-navi__list-item__anchor:hover::after {
  opacity: 1;
}

.l-footer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1F1F1F;
  padding-bottom: 1.6rem;
}
.l-footer-logosarea {
  padding-top: 5.6rem;
  display: flex;
  align-items: center;
  padding-bottom: 1.24rem;
}
.l-footer-logosarea-logobox {
  width: 16.5rem;
  margin-right: 2rem;
}
.l-footer-logosarea-line {
  position: relative;
  height: 6rem;
  width: 1px;
  margin-right: 2rem;
  background-color: #fff;
}
.l-footer-logosarea-text {
  color: #fff;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.3571428571;
  letter-spacing: 0.03em;
}
.l-footer-jump-corporate {
  margin-bottom: 5.67rem;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  line-height: 2.25;
  color: #fff;
}
.l-footer-jump-corporate-awesome {
  padding-left: 0.31rem;
}
.l-footer-copyrights {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  line-height: 2.25;
}

.l-hamburger {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #FAF7F2;
  z-index: 700;
  justify-content: space-between;
  transform: translateX(100%);
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: ease-out;
  overflow-y: scroll;
}
.l-hamburger-scroll {
  height: 100%;
  overflow-y: scroll;
}
.l-hamburger-container {
  width: 100%;
  height: max-content;
  min-height: 105%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-hamburger-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16.9rem;
}
.l-hamburger-nav__item {
  padding-bottom: 7rem;
}
.l-hamburger-nav__item__anchor {
  font-size: 2.5rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: #191919;
  letter-spacing: 0.1em;
  transition-duration: 0.5s;
  transition-property: color;
}
.l-hamburger-nav__item__anchor:hover {
  color: #9C0B2E;
}

.testtest {
  width: 100vw;
  height: 100vh;
  background-color: blue;
}

.l-main {
  width: 100vw;
  overflow-x: hidden;
}

.p-mv {
  background-color: #EBE8E2;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .p-mv {
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
  }
}
.p-mv-background {
  min-width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.p-mv-background__image {
  object-position: left;
  height: 100%;
}
.p-mv-textarea {
  padding-top: 25vh;
  padding-left: 3.515625vw;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 500;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea {
    padding-top: 15.525vh;
  }
}
.p-mv-textarea-titlearea__title {
  width: max-content;
  font-family: senobi-gothic, sans-serif;
  font-size: 5.3453125vw;
  font-weight: 500;
  color: #1A1A1A;
  line-height: 1.3800058462;
  padding-bottom: 3.29rem;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-titlearea__title {
    font-size: 12vw;
    padding-bottom: 2.05rem;
  }
}
.p-mv-textarea-titlearea__title--red {
  color: #9C0B2E;
}
.p-mv-textarea-titlearea__title--back-red {
  color: #fff;
  position: relative;
  z-index: 1;
}
.p-mv-textarea-titlearea__title--fs-small {
  font-size: 4.12421875vw;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-titlearea__title--fs-small {
    font-size: 9.3333333333vw;
  }
}
.p-mv-textarea-detail {
  display: flex;
  align-items: center;
  padding-bottom: 3.3rem;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail {
    flex-direction: column;
    margin-left: -2rem;
    padding-bottom: 1.76rem;
  }
}
.p-mv-textarea-detail-unit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.9734375vw;
  height: 7.146875vw;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail-unit {
    width: 85.3333333333vw;
    height: 5.421rem;
  }
}
.p-mv-textarea-detail-unit::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: 0;
  transform: skewX(-12deg);
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail-unit::after {
    transform: skewX(-24deg);
  }
}
.p-mv-textarea-detail-unit__item {
  position: relative;
  z-index: 1;
  color: #191919;
  text-align: center;
  font-size: 1.40625vw;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #191919;
  line-height: 1.3333333333;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail-unit__item {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail-unit__item .--br-sl-none {
    display: none;
  }
}
.p-mv-textarea-detail-unit .--red {
  font-size: 1.71875vw;
  font-weight: bold;
  color: #C81422;
  line-height: 1.0909090909;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail-unit .--red {
    font-size: 2rem;
  }
}
.p-mv-textarea-detail .top-down-change {
  flex-direction: column;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail .top-down-change {
    flex-direction: column-reverse;
  }
}
.p-mv-textarea-detail .--center {
  margin-left: 1.8rem;
  margin-right: 1.8rem;
}
@media screen and (max-width: 960px) {
  .p-mv-textarea-detail .--center {
    margin: 0;
    margin-top: 0.93rem;
    margin-bottom: 0.93rem;
  }
}
.p-mv-textarea-detail .--left {
  animation-delay: 0s;
}
.p-mv-textarea-detail .--center {
  animation-delay: 0.25s;
}
.p-mv-textarea-detail .--right {
  animation-delay: 0.5s;
}
.p-mv-imagearea {
  position: absolute;
  z-index: 400;
  width: 40.234375vw;
  height: max-content;
  transform: rotateZ(12deg);
  background-color: #fff;
  bottom: -10%;
  right: 4%;
}
@media screen and (max-width: 960px) {
  .p-mv-imagearea {
    position: relative;
    transform: rotateZ(-5deg);
    transform-origin: top right;
    bottom: 0;
    right: 0;
    width: 120vw;
    height: 20rem;
  }
}
.p-mv-imagearea-container {
  position: relative;
  width: 100%;
  height: 120vh;
  top: 0;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}
@media screen and (max-width: 960px) {
  .p-mv-imagearea-container {
    width: 100%;
    height: 100%;
  }
}
.p-mv-imagearea-frame {
  position: absolute;
  bottom: 0;
  height: max-content;
}
@media screen and (max-width: 960px) {
  .p-mv-imagearea-frame {
    width: max-content;
  }
}
.p-mv-imagearea__image {
  margin-top: -14rem;
  height: auto;
  width: 100%;
  position: relative;
  bottom: 0;
}
@media screen and (max-width: 960px) {
  .p-mv-imagearea__image {
    height: 20rem;
    width: auto;
    transform-origin: bottom;
  }
}

.p-message {
  width: 100vw;
  height: max-content;
  padding-top: 20rem;
  padding-bottom: 14.96rem;
  position: relative;
  background-color: #FAF7F2;
}
@media screen and (max-width: 960px) {
  .p-message {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.p-message__title {
  text-align: center;
  font-size: 8rem;
  color: #000;
  padding-bottom: 10rem;
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 960px) {
  .p-message__title {
    font-size: 5rem;
  }
}
@media screen and (max-width: 520px) {
  .p-message__title {
    padding-bottom: 5.02rem;
  }
}
.p-message .--title-sl {
  font-size: 6.842rem;
}
@media screen and (max-width: 960px) {
  .p-message .--title-sl {
    font-size: 4rem;
  }
}
.p-message__text {
  font-size: 1.8rem;
  color: #191919;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  text-align: center;
  position: relative;
  z-index: 100;
  letter-spacing: 0.1em;
  line-height: 2.5;
}
@media screen and (max-width: 520px) {
  .p-message__text {
    font-size: 1.6rem;
  }
}
.p-message__text .--br-sl-use {
  display: none;
}
@media screen and (max-width: 520px) {
  .p-message__text .--br-sl-use {
    display: block;
  }
}
.p-message .--text-lg {
  font-weight: bold;
  font-size: 2rem;
}
@media screen and (max-width: 520px) {
  .p-message .--text-lg {
    font-size: 1.8rem;
  }
}
.p-message__background {
  width: 100vw;
  position: absolute;
  z-index: 0;
  top: 0;
  height: 100%;
}
.p-message__background__image {
  position: absolute;
}
.p-message__background .--first-image {
  width: 42.4234375vw;
  top: -6.22rem;
  left: 3.37rem;
}
@media screen and (max-width: 960px) {
  .p-message__background .--first-image {
    width: 37.5rem;
    top: auto;
    bottom: 0;
    left: 0;
  }
}
.p-message__background .--seccond-image {
  width: 40.36015625vw;
  right: 5.74rem;
}
@media screen and (max-width: 960px) {
  .p-message__background .--seccond-image {
    width: 37.5rem;
    top: 0;
    right: 0;
  }
}
.p-message__background__text {
  color: #EBE8E2;
  font-size: 12.5vw;
  font-family: termina, sans-serif;
  text-align: center;
  transform: rotateZ(-3deg);
  opacity: 0.4;
  font-weight: 500;
  position: absolute;
  top: 54.35rem;
  letter-spacing: 0.1em;
  left: 5.078125vw;
}
@media screen and (max-width: 960px) {
  .p-message__background__text {
    top: 43.57rem;
  }
}

.p-pitch {
  width: 100vw;
  padding-bottom: 5.03rem;
  position: relative;
}
.p-pitch__background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.p-pitch__background__image {
  height: 100%;
}
.p-pitch__title {
  font-size: 6rem;
  text-align: center;
  line-height: 1.5;
  padding-top: 5.13rem;
  padding-bottom: 5rem;
  position: relative;
  z-index: 500;
}
@media screen and (max-width: 960px) {
  .p-pitch__title {
    font-size: 4rem;
    font-size: 10.6666666667vw;
  }
}
.p-pitch__title .--title-sl {
  font-size: 5.276rem;
}
@media screen and (max-width: 960px) {
  .p-pitch__title .--title-sl {
    font-size: 9.3333333333vw;
  }
}
.p-pitch__pitchbox {
  width: 68.16171875vw;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 960px) {
  .p-pitch__pitchbox {
    width: 88vw;
  }
}

.p-work {
  width: 100vw;
  background-color: #FAF7F2;
  position: relative;
  display: flex;
  flex-direction: column;
}
.p-work-title {
  font-size: 9rem;
  top: 8.18rem;
  left: 8.359375vw;
  position: absolute;
  width: max-content;
  z-index: 500;
}
@media screen and (max-width: 960px) {
  .p-work-title {
    position: relative;
    top: 0;
    padding-top: 10rem;
    padding-bottom: 5rem;
    font-size: 13.0666666667vw;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }
}
.p-work-title .--br-sl-use {
  display: none;
}
@media screen and (max-width: 960px) {
  .p-work-title .--br-sl-use {
    display: block;
  }
}
.p-work-title .--title-sl {
  font-size: 7.5rem;
}
@media screen and (max-width: 960px) {
  .p-work-title .--title-sl {
    font-size: 10.9333333333vw;
  }
}
.p-work-above {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 14.36rem;
  padding-bottom: 23.42rem;
  justify-content: flex-start;
  z-index: 100;
  position: relative;
}
@media screen and (max-width: 960px) {
  .p-work-above {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 5rem;
  }
}
.p-work-above-imagebox {
  width: 47.06171875vw;
}
@media screen and (max-width: 960px) {
  .p-work-above-imagebox {
    width: 62.5vw;
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 520px) {
  .p-work-above-imagebox {
    width: 94.6666666667vw;
    align-self: flex-start;
  }
}
.p-work-above-imagebox__image {
  height: auto;
}
.p-work-above-textarea {
  width: 42.109375vw;
  padding-left: 2.46875vw;
  padding-top: 16.6rem;
  align-self: flex-start;
}
@media screen and (max-width: 960px) {
  .p-work-above-textarea {
    width: 62.5vw;
    align-self: center;
    padding-top: 0;
  }
}
@media screen and (max-width: 520px) {
  .p-work-above-textarea {
    width: 94.6666666667vw;
  }
}
.p-work-above-textarea__text {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #191919;
  letter-spacing: 0.1em;
  line-height: 2.5;
}
.p-work-above-textarea__text .--text-fw {
  font-weight: bold;
}
.p-work-below__image {
  position: absolute;
  right: 0;
  bottom: -11.7rem;
  width: 39.8296875vw;
  z-index: 100;
}
@media screen and (max-width: 960px) {
  .p-work-below__image {
    position: relative;
    transform: translateX(50%);
    bottom: -10rem;
    margin: 0 auto;
    width: 62.5vw;
  }
}
@media screen and (max-width: 520px) {
  .p-work-below__image {
    width: 94.6666666667vw;
    right: 0;
    transform: translateX(0);
  }
}
.p-work-back-image-top {
  position: absolute;
  width: 33.455rem;
  top: 0;
  right: 10.7rem;
  z-index: 0;
}
@media screen and (max-width: 520px) {
  .p-work-back-image-top {
    width: 37.5rem;
    right: auto;
    left: -6.25rem;
  }
}
.p-work-back-image-bottom {
  position: absolute;
  width: 43.5rem;
  bottom: 3.14rem;
  left: 30.7421875vw;
  z-index: 0;
}
@media screen and (max-width: 520px) {
  .p-work-back-image-bottom {
    width: 37.5rem;
    bottom: 10.15rem;
    left: 0;
  }
}

.p-charm {
  width: 100vw;
  background-color: #fff;
  position: relative;
  padding-bottom: 10.28rem;
}
.p-charm__title {
  font-size: 9rem;
  padding-top: 14.7rem;
  padding-left: 10.7rem;
  position: relative;
  z-index: 500;
}
@media screen and (max-width: 960px) {
  .p-charm__title {
    text-align: center;
    padding-left: 0;
    font-size: 13.0666666667vw;
    padding-top: 15rem;
    padding-bottom: 5rem;
  }
}
.p-charm__title .--title-sl {
  font-size: 7.5rem;
}
@media screen and (max-width: 960px) {
  .p-charm__title .--title-sl {
    font-size: 10.9333333333vw;
  }
}
.p-charm__title .--br-sl-use {
  display: none;
}
@media screen and (max-width: 960px) {
  .p-charm__title .--br-sl-use {
    display: block;
  }
}
.p-charm-container {
  width: 83.28125vw;
  margin: 0 auto;
  padding-top: 5rem;
}
@media screen and (max-width: 960px) {
  .p-charm-container {
    padding-top: 0;
    width: 62.5vw;
  }
}
@media screen and (max-width: 520px) {
  .p-charm-container {
    width: 89.3333333333vw;
  }
}
.p-charm-unit {
  z-index: 100;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .p-charm-unit {
    flex-direction: column-reverse;
  }
}
.p-charm-unit__text {
  padding-top: 2.96rem;
  animation-delay: 0.5s;
}
@media screen and (max-width: 520px) {
  .p-charm-unit__text {
    padding-top: 3.3rem;
  }
}
.p-charm-unit__text-num {
  width: 14.431rem;
  padding-bottom: 5.05rem;
  box-sizing: content-box;
}
@media screen and (max-width: 520px) {
  .p-charm-unit__text-num {
    width: 10.255rem;
    height: 9.564rem;
    padding-bottom: 3.23rem;
  }
}
.p-charm-unit__text-subtitle {
  font-size: 2.5rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #191919;
  padding-bottom: 5.05rem;
}
@media screen and (max-width: 520px) {
  .p-charm-unit__text-subtitle {
    font-size: 2.3rem;
  }
}
.p-charm-unit__text-textbox {
  width: 37.890625vw;
}
@media screen and (max-width: 960px) {
  .p-charm-unit__text-textbox {
    width: 100%;
  }
}
.p-charm-unit__text-textbox__text {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #1B1D1E;
  line-height: 2.0625;
  letter-spacing: 0.1em;
}
.p-charm-unit-imagebox {
  width: 47.4549718574%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .p-charm-unit-imagebox {
    width: 100%;
  }
}
.p-charm-unit-imagebox__image {
  height: auto;
}
.p-charm .-unit-center {
  padding-top: 10.08rem;
  padding-bottom: 7.96rem;
}
@media screen and (max-width: 960px) {
  .p-charm .-unit-center {
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.p-charm .-charm-background {
  position: absolute;
  z-index: 0;
}
.p-charm-background-top-left {
  top: 1.45rem;
  left: 1.3rem;
  width: 41.355rem;
}
@media screen and (max-width: 520px) {
  .p-charm-background-top-left {
    width: 28.739rem;
    top: 10rem;
    left: 0;
  }
}
.p-charm-background-mid-right {
  bottom: 98.08rem;
  right: 0;
  width: 36.118rem;
}
@media screen and (max-width: 960px) {
  .p-charm-background-mid-right {
    right: 50%;
    transform: translateX(50%);
    bottom: 130rem;
  }
}
@media screen and (max-width: 520px) {
  .p-charm-background-mid-right {
    top: 408vw;
    bottom: auto;
  }
}
.p-charm-background-bottom {
  bottom: 31.31rem;
  left: 26.95rem;
  width: 52.688rem;
}
@media screen and (max-width: 960px) {
  .p-charm-background-bottom {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 520px) {
  .p-charm-background-bottom {
    bottom: 101.3333333333vw;
    width: 38.65rem;
  }
}

.p-voice {
  width: 100vw;
  background-color: #FAF7F2;
  position: relative;
}
.p-voice-background-first {
  position: absolute;
  width: 8.691rem;
  left: 62.8125vw;
  top: 4.56rem;
}
@media screen and (max-width: 960px) {
  .p-voice-background-first {
    top: 0.4rem;
    left: 78.08vw;
  }
}
.p-voice-background-seccond {
  position: absolute;
  width: 8.691rem;
  left: 29.5859375vw;
  transform: rotateZ(-15deg);
  top: 18.86rem;
}
@media screen and (max-width: 960px) {
  .p-voice-background-seccond {
    top: 8.98rem;
    left: 6.56vw;
  }
}
.p-voice-background-third {
  position: absolute;
  width: 8.691rem;
  right: 4rem;
  top: 21.13rem;
}
@media screen and (max-width: 960px) {
  .p-voice-background-third {
    display: none;
  }
}
.p-voice__title {
  font-size: 9rem;
  color: #1A1A1A;
  text-align: center;
  padding-top: 10rem;
}
@media screen and (max-width: 960px) {
  .p-voice__title {
    font-size: 5.6rem;
    padding-top: 5rem;
  }
}
.p-voice__title .--title-sl {
  font-size: 7.5rem;
}
@media screen and (max-width: 960px) {
  .p-voice__title .--title-sl {
    font-size: 4.7rem;
  }
}
.p-voice-container {
  width: 83.28125vw;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .p-voice-container {
    flex-direction: column;
    padding-top: 5rem;
  }
}
@media screen and (max-width: 520px) {
  .p-voice-container {
    width: 89.3333333333vw;
  }
}
.p-voice-unit {
  width: 25.703125vw;
  margin-top: 13.16rem;
  background-color: #fff;
  min-height: 81.4rem;
  box-shadow: 0px 3px 6px rgba(141, 141, 142, 0.16);
}
@media screen and (max-width: 960px) {
  .p-voice-unit {
    width: 100%;
    margin: 0;
  }
}
.p-voice-unit-imagebox {
  width: 100%;
}
.p-voice-unit__name {
  font-size: 2.3rem;
  color: #191919;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  text-align: center;
  padding-top: 2.26rem;
  letter-spacing: 0.1em;
  line-height: 1.3043478261;
}
.p-voice-unit__position {
  color: #9C0B2E;
  font-size: 2rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 1.5;
  padding-bottom: 3.01rem;
}
@media (max-width: 1080px) {
  .p-voice-unit__position .--sl-none {
    display: none;
  }
}
.p-voice-unit__position .--br-sl-use {
  display: none;
}
@media screen and (max-width: 960px) {
  .p-voice-unit__position .--br-sl-use {
    display: block;
  }
}
.p-voice-unit__position .--br-sl-none-use {
  display: none;
}
@media (max-width: 1080px) {
  .p-voice-unit__position .--br-sl-none-use {
    display: block;
  }
}
@media screen and (max-width: 960px) {
  .p-voice-unit__position .--br-sl-none-use {
    display: none;
  }
}
.p-voice-unit__text {
  padding-left: 3.3rem;
  padding-right: 3.3rem;
  padding-bottom: 2.03rem;
  color: #1B1D1E;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  line-height: 2.0625;
}
.p-voice .--left {
  animation-delay: 0s;
}
.p-voice .--center {
  animation-delay: 0.25s;
}
.p-voice .--right {
  animation-delay: 0.5s;
}
@media screen and (max-width: 960px) {
  .p-voice .--unit-center {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.p-recruit {
  width: 100vw;
  background-color: #FAF7F2;
  padding-bottom: 9.3rem;
}
.p-recruit__title {
  font-size: 9rem;
  width: max-content;
  height: max-content;
  margin: 0 auto;
  padding-top: 10.6rem;
  position: relative;
  color: #191919;
  z-index: 500;
}
@media screen and (max-width: 520px) {
  .p-recruit__title {
    font-size: 5.9rem;
  }
}
.p-recruit .--under-line {
  position: absolute;
  width: 28rem;
  height: 1.7rem;
  background-color: #FF7B34;
  left: 50%;
  transform: translateX(-46.5%);
  margin-top: -2rem;
  z-index: 0;
}
@media screen and (max-width: 520px) {
  .p-recruit .--under-line {
    width: 18.551rem;
    height: 1.136rem;
    margin-top: -1.5rem;
  }
}
.p-recruit__sub-text {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #1B1D1E;
  line-height: 2.0625;
  letter-spacing: 0.1em;
  margin: 0 auto;
  padding-top: 3.71rem;
  padding-left: 3.5%;
  padding-bottom: 5.05rem;
  width: max-content;
}
@media screen and (max-width: 520px) {
  .p-recruit__sub-text {
    text-align: center;
    padding-top: 2.99rem;
    padding-bottom: 5rem;
  }
}
.p-recruit__sub-text .--br-sl-use {
  display: none;
}
@media screen and (max-width: 520px) {
  .p-recruit__sub-text .--br-sl-use {
    display: block;
  }
}
.p-recruit-units-container {
  width: 83.28125vw;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media screen and (max-width: 960px) {
  .p-recruit-units-container {
    width: 55%;
    transform: scale(1.4);
    transform-origin: top;
    padding-bottom: 25vw;
  }
}
@media screen and (max-width: 520px) {
  .p-recruit-units-container {
    width: 100%;
    justify-content: center;
    transform: scale(1);
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
}
.p-recruit-unit {
  width: 26.171875vw;
  height: 26.171875vw;
  background-color: #fff;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(141, 141, 142, 0.16);
}
@media screen and (max-width: 520px) {
  .p-recruit-unit {
    width: 89.3333333333vw;
    height: 89.3333333333vw;
  }
}
.p-recruit-unit-imagebox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.p-recruit-unit .--size-money {
  width: 42.5641791045%;
}
.p-recruit-unit .--size-incentive {
  width: 47.2865671642%;
}
.p-recruit-unit .--size-time {
  width: 64.1373134328%;
}
.p-recruit-unit .--size-train {
  width: 44.0089552239%;
}
.p-recruit-unit .--size-cheers {
  width: 49.9820895522%;
}
.p-recruit-unit .--size-dress {
  width: 59.1044776119%;
}
.p-recruit-unit-textbox {
  position: relative;
  z-index: 100;
  text-align: center;
}
.p-recruit-unit-textbox__text {
  color: #191919;
  font-size: 2.734375vw;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox__text {
    font-size: 10.447761194vw;
  }
}
.p-recruit-unit-textbox__text .--ff-oswald {
  font-family: "Oswald", sans-serif;
}
.p-recruit-unit-textbox__text .--fw-mid {
  font-weight: 500;
}
.p-recruit-unit-textbox__text .--fw-bold {
  font-weight: bold;
}
.p-recruit-unit-textbox__text .--fs-25 {
  font-size: 1.953125vw;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox__text .--fs-25 {
    font-size: 7.4626865672vw;
  }
}
.p-recruit-unit-textbox__text .--fs-30 {
  font-size: 2.34375vw;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox__text .--fs-30 {
    font-size: 8.9552238806vw;
  }
}
.p-recruit-unit-textbox__text .--fs-41 {
  font-size: 3.203125vw;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox__text .--fs-41 {
    font-size: 12.2388059701vw;
  }
}
.p-recruit-unit-textbox__text .--fs-58 {
  font-size: 4.53125vw;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox__text .--fs-58 {
    font-size: 17.3134328358vw;
  }
}
.p-recruit-unit-textbox .--top-position-money {
  padding-top: 6.25vw;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-money {
    padding-top: 13.3333333333vw;
  }
}
.p-recruit-unit-textbox .--top-position-incentive {
  padding-top: 7.734375vw;
  white-space: nowrap;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-incentive {
    padding-top: 29.3333333333vw;
  }
}
.p-recruit-unit-textbox .--top-position-time {
  padding-top: 8.203125vw;
  line-height: 1.3902439024;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-time {
    padding-top: 28vw;
  }
}
.p-recruit-unit-textbox .--top-position-train {
  padding-top: 9.84375vw;
  line-height: 1.4;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-train {
    padding-top: 33.3333333333vw;
  }
}
.p-recruit-unit-textbox .--top-position-cheers {
  padding-top: 10.390625vw;
  line-height: 1.2;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-cheers {
    padding-top: 35.4666666667vw;
  }
}
.p-recruit-unit-textbox .--top-position-dress {
  padding-top: 10.078125vw;
  line-height: 1.2;
}
@media screen and (max-width: 520px) {
  .p-recruit-unit-textbox .--top-position-dress {
    padding-top: 34.4vw;
  }
}
.p-recruit-unit-textbox .--line-height-incentive {
  line-height: 1.2;
}
.p-recruit .--left {
  animation-delay: 0s;
}
.p-recruit .--center {
  animation-delay: 0.25s;
}
.p-recruit .--right {
  animation-delay: 0.5s;
}
.p-recruit .--above {
  margin-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .p-recruit .--above {
    margin-bottom: 3rem;
  }
}
.p-recruit .--below {
  margin-bottom: 5rem;
}
@media screen and (max-width: 960px) {
  .p-recruit .--below {
    margin-bottom: 3rem;
  }
}
.p-recruit .--bottom {
  margin-bottom: 0;
}

.p-flow {
  width: 100vw;
  background-color: #FAF7F2;
  padding-bottom: 10rem;
}
@media screen and (max-width: 960px) {
  .p-flow {
    padding-bottom: 7rem;
  }
}
.p-flow-titlebox {
  width: 83.28125vw;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5.02rem;
}
@media screen and (max-width: 960px) {
  .p-flow-titlebox {
    flex-direction: column;
    align-items: flex-start;
  }
}
.p-flow-titlebox-imagebox {
  width: 28.705rem;
}
@media screen and (max-width: 520px) {
  .p-flow-titlebox-imagebox {
    width: 13.618rem;
  }
}
.p-flow-titlebox__title {
  width: 100%;
  padding-left: 3.8rem;
  border-bottom: 1px solid #9C0B2E;
  font-size: 5.276rem;
  color: #1A1A1A;
}
@media screen and (max-width: 960px) {
  .p-flow-titlebox__title {
    padding-left: 0;
    border-bottom: 0px;
    border-top: 1px solid #9C0B2E;
    padding-top: 2.03rem;
    margin-top: 2.03rem;
  }
}
@media screen and (max-width: 520px) {
  .p-flow-titlebox__title {
    font-size: 3rem;
  }
}
.p-flow-titlebox__title .--title-sl {
  font-size: 4.2rem;
}
@media screen and (max-width: 520px) {
  .p-flow-titlebox__title .--title-sl {
    font-size: 2.3rem;
  }
}
.p-flow-units-container {
  width: 83.28125vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 960px) {
  .p-flow-units-container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 7rem;
  }
}
.p-flow-units-container-background {
  width: 100%;
  height: 1.5rem;
  background-color: #9C0B2E;
  position: absolute;
  z-index: 0;
  top: 50%;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  transform: scaleX(0);
  transform-origin: left;
}
@media screen and (max-width: 960px) {
  .p-flow-units-container-background {
    height: 80%;
    width: 1.5rem;
    top: 1px;
    transform: scaleY(0);
    transform-origin: top;
  }
}
.p-flow-unit {
  width: 24.453125vw;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(141, 141, 142, 0.16);
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 960px) {
  .p-flow-unit {
    width: 55.7932263815vw;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 520px) {
  .p-flow-unit {
    width: 83.4666666667vw;
  }
}
.p-flow-unit-imagebox {
  padding-top: 3.6rem;
}
.p-flow-unit .--image-size-first {
  width: 55.7987220447%;
  margin-left: 24.6006389776%;
  padding-bottom: 1.8rem;
}
.p-flow-unit .--image-size-seccond {
  width: 69.0031948882%;
  margin-left: 15.6549520767%;
  padding-bottom: 1.75rem;
}
.p-flow-unit .--image-size-third {
  width: 71%;
  margin-left: 14.3769968051%;
  padding-bottom: 1.75rem;
}
.p-flow-unit-title {
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  padding-bottom: 2rem;
}
.p-flow-unit-title-imagebox {
  width: 18.8498402556%;
}
.p-flow-unit-title__title {
  color: #191919;
  font-size: 1.953125vw;
  padding-left: 1.5rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 960px) {
  .p-flow-unit-title__title {
    font-size: 2.5rem;
  }
}
.p-flow-unit__text {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: 1.6rem;
  color: #1B1D1E;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  padding-bottom: 3.29rem;
  letter-spacing: 0.1em;
  line-height: 1.5625;
}
.p-flow .--left {
  animation-delay: 0s;
}
.p-flow .--center {
  animation-delay: 0.25s;
}
.p-flow .--right {
  animation-delay: 0.5s;
}

.p-entryarea {
  width: 100vw;
  background-color: #9C0B2E;
  padding-bottom: 4.8rem;
  position: relative;
}
.p-entryarea-background {
  position: absolute;
  height: 100%;
}
.p-entryarea-background__image {
  height: 100%;
}
.p-entryarea-titlebox {
  width: 83.28125vw;
  margin: 0 auto;
  padding-top: 7.1rem;
  padding-bottom: 3.08rem;
  text-align: center;
}
.p-entryarea-titlebox__title {
  font-size: 4.53125vw;
  color: #fff;
  line-height: 1.2068965517;
}
@media screen and (max-width: 960px) {
  .p-entryarea-titlebox__title {
    width: 100%;
    font-size: 3.8rem;
  }
}
.p-entryarea-titlebox__title .-l-spacing-80 {
  letter-spacing: -0.08em;
}
.p-entryarea-titlebox__title .--title-sl {
  font-size: 3.828125vw;
}
@media screen and (max-width: 960px) {
  .p-entryarea-titlebox__title .--title-sl {
    font-size: 3.1rem;
  }
}
.p-entryarea-titlebox__title .--br-sl-use {
  display: none;
}
@media screen and (max-width: 960px) {
  .p-entryarea-titlebox__title .--br-sl-use {
    display: block;
  }
}

.p-form {
  width: 100%;
  background-color: #FAF7F2;
  padding-bottom: 10rem;
}
.p-form-page-show {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  color: #1B1D1E;
  padding-top: 15.6rem;
  padding-left: 8.359375vw;
  padding-bottom: 5.3rem;
}
@media screen and (max-width: 960px) {
  .p-form-page-show {
    padding-top: 8.9rem;
    padding-bottom: 10rem;
  }
}
.p-form-page-show__arrow {
  color: #191919;
  padding-left: 1.07rem;
  padding-right: 1.07rem;
}
.p-form__title-en {
  font-size: 3.5rem;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  line-height: 0.9428571429;
  color: #9C0B2E;
  text-align: center;
  padding-bottom: 0.9rem;
}
.p-form__title-ja {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  line-height: 2.0625;
  text-align: center;
  padding-bottom: 5.05rem;
}
.p-form-stepsbox {
  width: max-content;
  padding-right: 2.3rem;
  padding-left: 2.3rem;
  padding-top: 2.65rem;
  padding-bottom: 2.65rem;
  border-top: 1px solid #8D8D8E;
  border-bottom: 1px solid #8D8D8E;
  margin: 0 auto;
  margin-bottom: 5.15rem;
}
.p-form-stepsbox__steps {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
}
.p-form-stepsbox__steps .--arrow {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p-form-stepsbox__steps .--red {
  color: #9C0B2E;
}
.p-form-explain {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  line-height: 2.0625;
  color: #1B1D1E;
  text-align: center;
  padding-bottom: 5rem;
  width: 89.3333333333vw;
  margin: 0 auto;
}
.p-form-explain .--br-sl-use {
  display: none;
}
@media screen and (max-width: 960px) {
  .p-form-explain .--br-sl-use {
    display: block;
  }
}
.p-form-container {
  width: 77.5rem;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .p-form-container {
    width: 89.3333333333vw;
  }
}
.p-form-unit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #8D8D8E;
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .p-form-unit {
    flex-direction: column;
    align-items: flex-start;
  }
}
.p-form-unit-titles-container {
  display: flex;
  align-items: center;
  padding-right: 4.1rem;
  width: 27.8rem;
  box-sizing: content-box;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .p-form-unit-titles-container {
    justify-content: flex-start;
    padding-bottom: 1.85rem;
    padding-right: 0;
  }
}
.p-form-unit__title {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  color: #1B1D1E;
}
@media screen and (max-width: 960px) {
  .p-form-unit__title {
    padding-right: 2rem;
  }
}
.p-form-unit__req-or-any {
  font-size: 1rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.5em;
  padding-top: 0.5rem;
  padding-right: 1.6rem;
  padding-bottom: 0.5rem;
  padding-left: 1.6rem;
}
.p-form-unit .--required {
  border: 1px solid #9C0B2E;
  color: #9C0B2E;
}
.p-form-unit .--any {
  border: 1px solid #B9B9B9;
  color: #B9B9B9;
}
.p-form-unit-selectbox-container {
  display: flex;
  justify-content: space-between;
  width: 45.6rem;
}
@media screen and (max-width: 960px) {
  .p-form-unit-selectbox-container {
    flex-direction: column;
    width: 89.3333333333vw;
    max-width: 33.5rem;
    margin: 0 auto;
  }
}
.p-form-unit-selectbox {
  display: flex;
  align-items: center;
}
@media (max-width: 370px) {
  .p-form-unit-selectbox {
    justify-content: center;
  }
}
.p-form-unit-selectbox__select {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: max-content;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
}
.p-form-unit-selectbox__select::after {
  content: "";
  position: absolute;
  border-top: 0.9rem solid #191919;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  top: 50%;
  transform: translateY(-50%);
  right: 1.8rem;
}
@media screen and (max-width: 960px) {
  .p-form-unit-selectbox .--year {
    width: max-content;
  }
}
@media screen and (max-width: 960px) {
  .p-form-unit-selectbox .--month {
    width: max-content;
  }
}
@media screen and (max-width: 960px) {
  .p-form-unit-selectbox .--day {
    width: max-content;
  }
}
.p-form-unit-selectbox-day-month {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}
@media screen and (max-width: 960px) {
  .p-form-unit-selectbox-day-month {
    justify-content: space-between;
    padding-top: 1rem;
  }
}
@media (max-width: 375px) {
  .p-form-unit-selectbox-day-month {
    justify-content: space-around;
  }
}
.p-form-unit-selectbox-days {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #191919;
  padding-left: 0.8rem;
}
.p-form-unit-bottom-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 2.8rem;
}
@media screen and (max-width: 960px) {
  .p-form-unit-bottom-wrapper {
    margin: 0 auto;
  }
}
.p-form-unit-bottom-wrapper__inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 1.6rem;
}
@media screen and (max-width: 960px) {
  .p-form-unit-bottom-wrapper__inner {
    flex-direction: column;
    align-items: flex-start;
  }
}
.p-form-unit-bottom-num {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #1B1D1E;
  letter-spacing: 0.1em;
  width: 27.8rem;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .p-form-unit-bottom-num {
    width: max-content;
    padding-bottom: 2rem;
  }
}
.p-form .--unit-bottom {
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #8D8D8E;
  margin-bottom: 5rem;
}
.p-form .--unit-bottom-titles {
  width: auto;
}
.p-form-agree {
  display: flex;
  margin: 0 auto;
  width: max-content;
  padding-bottom: 5rem;
  align-items: center;
}
.p-form-agree__text {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #707070;
  width: max-content;
  padding-left: 1rem;
  line-height: 1.875;
}
.p-form-agree__text .--br-sl-use {
  display: none;
}
@media screen and (max-width: 520px) {
  .p-form-agree__text .--br-sl-use {
    display: block;
  }
}
.p-form-agree-check {
  display: none;
}
.p-form-agree-checkbox {
  width: 1.9rem;
  height: 1.9rem;
  background-color: #fff;
  border: 1px solid #1B1D1E;
}
.p-form-agree .checkbox-active {
  position: relative;
}
.p-form-agree .checkbox-active::after {
  content: "";
  position: absolute;
  width: 20%;
  height: 50%;
  top: 50%;
  left: 10%;
  transform: rotateZ(-45deg);
  background-color: #9C0B2E;
}
.p-form-agree .checkbox-active::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 80%;
  bottom: 0;
  left: 50%;
  transform: rotateZ(45deg);
  background-color: #9C0B2E;
}
.p-form .--grade-select-unit {
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .p-form .--grade {
    align-self: center;
  }
}

.p-form-return {
  margin: 0 auto;
  padding-top: 2.6rem;
  display: block;
}
.p-form-return__anchor {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #191919;
}

.p-thanks {
  width: 100%;
  background-color: #FAF7F2;
  padding-bottom: 10rem;
}
.p-thanks-page-show {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  color: #1B1D1E;
  padding-top: 15.6rem;
  padding-left: 8.359375vw;
  padding-bottom: 5.3rem;
}
.p-thanks-page-show .--br-sl-use {
  display: none;
}
@media screen and (max-width: 520px) {
  .p-thanks-page-show .--br-sl-use {
    display: block;
    content: "";
    height: 1rem;
  }
}
@media screen and (max-width: 960px) {
  .p-thanks-page-show {
    padding-top: 8.9rem;
    padding-bottom: 10rem;
  }
}
.p-thanks-page-show__arrow {
  color: #191919;
  padding-left: 1.07rem;
  padding-right: 1.07rem;
}
.p-thanks__title-en {
  font-size: 3.5rem;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.1em;
  line-height: 0.9428571429;
  color: #9C0B2E;
  text-align: center;
  padding-bottom: 0.9rem;
}
.p-thanks__title-ja {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 0.1em;
  line-height: 2.0625;
  text-align: center;
  padding-bottom: 5.05rem;
}
.p-thanks-stepsbox {
  width: max-content;
  padding-right: 2.3rem;
  padding-left: 2.3rem;
  padding-top: 2.65rem;
  padding-bottom: 2.65rem;
  border-top: 1px solid #8D8D8E;
  border-bottom: 1px solid #8D8D8E;
  margin: 0 auto;
  margin-bottom: 5.15rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
}
.p-thanks-stepsbox__steps {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.p-thanks-stepsbox__steps .--arrow {
  padding-right: 3rem;
  padding-left: 3rem;
}
.p-thanks-stepsbox__steps .--red {
  color: #9C0B2E;
}
.p-thanks-textbox {
  padding-top: 5.05rem;
  padding-bottom: 5rem;
  border-top: 1px solid #8D8D8E;
  border-bottom: 1px solid #8D8D8E;
  width: 60.58515625vw;
  margin: 0 auto;
  padding-left: 4.6rem;
  padding-right: 4.6rem;
}
@media screen and (max-width: 960px) {
  .p-thanks-textbox {
    padding-left: 0;
    padding-right: 0;
    width: 89.3333333333vw;
  }
}
.p-thanks-textbox__text {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  line-height: 1.6875;
}
.p-thanks-textbox-list {
  padding-top: 1.6rem;
  padding-bottom: 3.15rem;
  list-style-type: disc;
  padding-left: 1.6rem;
}
.p-thanks-textbox__red-text {
  font-size: 2rem;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #9C0B2E;
}

.c-header-right-entry-button {
  width: 17.2rem;
  height: 5.4rem;
  background-color: #fff;
  border-radius: 2.7rem;
  border: 1px solid #C81422;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.c-header-right-entry-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #C81422;
  z-index: 0;
  left: 0;
  transition-duration: 0.3s;
}
.c-header-right-entry-button:hover::after {
  left: 100%;
}
.c-header-right-entry-button:hover .c-header-right-entry-button__inner-en {
  color: #C81422;
}
.c-header-right-entry-button:hover .c-header-right-entry-button__inner-ja {
  color: #C81422;
}
.c-header-right-entry-button__inner-en {
  display: block;
  color: #fff;
  font-size: 1.8rem;
  font-family: termina, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  position: relative;
  z-index: 1;
  transition-duration: 0.3s;
}
.c-header-right-entry-button__inner-ja {
  font-size: 1rem;
  color: #fff;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  position: relative;
  z-index: 1;
  transition-duration: 0.3s;
}

.c-header-right-hamburger-button {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 100%;
  background-color: #9C0B2E;
  border: 1px solid #9C0B2E;
  align-items: center;
  justify-content: center;
  display: none;
  flex-direction: column;
  transition-duration: 0.5s;
  transition-property: transform, background-color;
}
@media screen and (max-width: 960px) {
  .c-header-right-hamburger-button {
    display: flex;
  }
}
.c-header-right-hamburger-button-line {
  display: block;
  width: 2.239rem;
  height: 1px;
  background-color: #fff;
  position: relative;
  transition-duration: 0.5s;
  transition-property: transform, background-color, opacity, top, bottom;
}
.c-header-right-hamburger-button .__top {
  top: -0.59rem;
}
.c-header-right-hamburger-button .__bottom {
  bottom: -0.59rem;
}

.c-mv-textarea-entry {
  width: 24.634375vw;
  height: 4.44921875vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #C81422;
  border-radius: 2.224609375vw;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 12.4vh;
  box-shadow: 4px 4px 3px rgba(52, 52, 52, 0.16);
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}
@media screen and (max-width: 960px) {
  .c-mv-textarea-entry {
    display: none;
  }
}
.c-mv-textarea-entry::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #C81422;
  z-index: 0;
  transition-duration: 0.3s;
}
.c-mv-textarea-entry:hover::after {
  left: 100%;
}
.c-mv-textarea-entry__inner {
  width: max-content;
  font-size: 1.484375vw;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;
  transition-duration: 0.3s;
}
.c-mv-textarea-entry:hover .c-mv-textarea-entry__inner {
  color: #C81422;
}

.c-entryarea-button {
  width: 38.6rem;
  height: 5.1rem;
  background-color: #9C0B2E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2.55rem;
  margin: 0 auto;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;
  z-index: 100;
}
.c-entryarea-button::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 0;
  transition-duration: 0.5s;
  transition-property: transform;
}
.c-entryarea-button:hover::after {
  transform: translateX(100%);
}
.c-entryarea-button:hover .c-entryarea-button__inner-en {
  color: #fff;
}
.c-entryarea-button:hover .c-entryarea-button__inner-ja {
  color: #fff;
}
@media screen and (max-width: 520px) {
  .c-entryarea-button {
    width: 81.0666666667vw;
    height: 5.5rem;
  }
}
.c-entryarea-button__inner-en {
  font-size: 1.8rem;
  font-family: termina, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.1111111111;
  color: #9C0B2E;
  position: relative;
  z-index: 100;
  transition-duration: 0.5s;
  transition-property: color;
}
.c-entryarea-button__inner-ja {
  font-size: 1rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: #9C0B2E;
  line-height: 1.5;
  position: relative;
  z-index: 100;
  transition-duration: 0.5s;
  transition-property: color;
}

.c-mv-sm-entry-button {
  width: 31.532rem;
  height: 5.695rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #C81422;
  border-radius: 7.5933333333vw;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 4px 4px 3px rgba(52, 52, 52, 0.16);
  z-index: 500;
  display: none;
  margin-top: 1.9rem;
}
@media screen and (max-width: 960px) {
  .c-mv-sm-entry-button {
    display: flex;
  }
}
@media screen and (max-width: 520px) {
  .c-mv-sm-entry-button {
    width: 93.75vw;
  }
}
.c-mv-sm-entry-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #C81422;
  z-index: 0;
  transition-duration: 0.3s;
}
.c-mv-sm-entry-button:hover::after {
  left: 100%;
}
.c-mv-sm-entry-button__inner {
  width: max-content;
  font-size: 1.9rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 1;
  transition-duration: 0.3s;
}
.c-mv-sm-entry-button:hover .c-mv-sm-entry-button__inner {
  color: #C81422;
}

.c-hamburger-entry-button {
  width: 100%;
  height: auto;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #9C0B2E;
  position: relative;
}
.c-hamburger-entry-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #9C0B2E;
  z-index: 0;
  transition-duration: 0.5s;
  transition-property: transform;
}
.c-hamburger-entry-button:hover::after {
  transform: translateX(100%);
}
.c-hamburger-entry-button:hover .c-hamburger-entry-button__inner-en {
  color: #9C0B2E;
}
.c-hamburger-entry-button:hover .c-hamburger-entry-button__inner-ja {
  color: #9C0B2E;
}
.c-hamburger-entry-button__inner-en {
  font-size: 3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.2em;
  padding-top: 4.2rem;
  transition-duration: 0.5s;
  transition-property: color;
  position: relative;
  z-index: 100;
}
.c-hamburger-entry-button__inner-ja {
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1em;
  padding-top: 2rem;
  transition-duration: 0.5s;
  transition-property: color;
  position: relative;
  z-index: 100;
  padding-bottom: 4.2rem;
}

.c-form-confirm {
  width: 38.6rem;
  height: 5.1rem;
  background-color: #fff;
  border: 1px solid #9C0B2E;
  border-radius: 2.55rem;
  position: relative;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 520px) {
  .c-form-confirm {
    width: 89.3333333333vw;
  }
}
.c-form-confirm::after {
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #9C0B2E;
  z-index: 0;
  transition-duration: 0.5s;
  transition-property: transform;
}
.c-form-confirm:hover::after {
  transform: translateX(100%);
}
.c-form-confirm:hover .c-form-confirm__inner {
  color: #9C0B2E;
}
.c-form-confirm__inner {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #fff;
  z-index: 100;
  position: relative;
  transition-duration: 0.5s;
  transition-property: color;
}

.c-thanks-return-top {
  width: 38.6rem;
  height: 5.1rem;
  border: 1px solid #9C0B2E;
  border-radius: 2.55rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-top: 5rem;
  z-index: 100;
}
@media screen and (max-width: 520px) {
  .c-thanks-return-top {
    width: 89.3333333333vw;
  }
}
.c-thanks-return-top::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #9C0B2E;
  position: absolute;
  top: 0;
  transition-duration: 0.5s;
  transition-property: transform;
}
.c-thanks-return-top:hover::after {
  transform: translateX(100%);
}
.c-thanks-return-top:hover .c-thanks-return-top__inner {
  color: #9C0B2E;
}
.c-thanks-return-top__inner {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  color: #fff;
  position: relative;
  transition-duration: 0.5s;
  transition-property: color;
  z-index: 100;
}
.c-thanks-return-top__inner__arrow {
  padding-right: 1.5rem;
  font-size: 2rem;
}

.c--title-red {
  color: #9C0B2E;
}

.c-title--back-red {
  position: relative;
  color: #fff;
  z-index: 100;
}
.c-title--back-red__inner {
  width: 97%;
  height: 88%;
  background-color: #9C0B2E;
  display: block;
  position: absolute;
  left: 1.5%;
  top: 6%;
  opacity: 0;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.c-title-init {
  font-weight: 500;
  font-family: senobi-gothic, sans-serif;
  letter-spacing: -0.22em;
  overflow: hidden;
}

.c-title-init-none-l-spacing {
  font-weight: 500;
  font-family: senobi-gothic, sans-serif;
}

.c-title-anim-one-letter {
  display: inline-block;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}

@keyframes titleUpAnim {
  0% {
    transform: translateY(60px);
    opacity: 0;
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.c--text-red {
  color: #9C0B2E;
}

.c-form-unit-input {
  font-size: 1.6rem;
  background-color: #fff;
  padding-top: 1rem;
  padding-left: 1.6rem;
  padding-bottom: 1rem;
  border: 1px solid #C7C7C7;
  width: 45.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
}
@media screen and (max-width: 960px) {
  .c-form-unit-input {
    width: 100%;
  }
}

.c-form-unit-selectbox__select-inner {
  font-size: 1.6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  background-color: #fff;
  border: 1px solid #C7C7C7;
}
.--select-year {
  padding-right: 5.2rem;
  padding-left: 5.2rem;
}
@media screen and (max-width: 960px) {
  .--select-year {
    padding-right: 13.4rem;
    padding-left: 13.4rem;
    text-align: center;
  }
}
@media (max-width: 370px) {
  .--select-year {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

.--select-month {
  padding-right: 4.2rem;
  padding-left: 4.2rem;
}
@media screen and (max-width: 960px) {
  .--select-month {
    padding-right: 6.3rem;
    padding-left: 6.3rem;
    text-align: center;
  }
}
@media (max-width: 370px) {
  .--select-month {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.--select-day {
  padding-right: 4.2rem;
  padding-left: 4.2rem;
}
@media screen and (max-width: 960px) {
  .--select-day {
    padding-right: 6.3rem;
    padding-left: 6.3rem;
    text-align: center;
  }
}
@media (max-width: 370px) {
  .--select-day {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.--grade-select {
  padding-right: 10rem;
  padding-left: 10rem;
}
@media screen and (max-width: 960px) {
  .--grade-select {
    align-self: center;
  }
}

.c-animation-init {
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  opacity: 0;
}

.c-animation-line {
  animation-name: lineExtendLg;
}
@media screen and (max-width: 960px) {
  .c-animation-line {
    animation-name: lineExtendSm;
  }
}

@keyframes lineExtendLg {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(100%);
  }
}
@keyframes lineExtendSm {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(100%);
  }
}
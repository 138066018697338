@use "../foundation/variables" as *;
@use "../foundation/mixin" as *;

.c--title-red {
  color: $cWineRed;
}

.c-title--back-red {
	position: relative;
	color: $cWhite;
	@include z-index(text);
	&__inner{
		width: 97%;
		height: 88%;
		background-color: $cWineRed;
		display: block;
		position: absolute;
		left: 1.5%;
		top: 6%;
		opacity: 0;
		animation-duration: 0.5s;
		animation-iteration-count: 1;
		animation-fill-mode: both;
	}
}

.c-title-init{
	font-weight: $fw-mid;
	font-family: $ff-senobi;
	@include l-spacing(-220);
	overflow: hidden;
}

.c-title-init-none-l-spacing{
	font-weight: $fw-mid;
	font-family: $ff-senobi;
}

.c-title-anim-one-letter{
	display: inline-block;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: both;
	opacity: 0;
}

@keyframes titleUpAnim{
	0%{
		transform: translateY(60px);
		opacity: 0;
	}
	80%{
		transform: translateY(-10px);
	}
	100%{
		transform: translateY(0px);
		opacity: 1;
	}
}
/* ===============================================
# 変数設定用のファイルです
=============================================== */

/* ===============================================
# 色
=============================================== */
$cWhite: #fff;
$cBlack: #000;
$cBoldBlack: #191919;
$cMidBlack: #1A1A1A;
$cBlueBlack :#1B1D1E;
$cLightBlueBlack: #31313D;
$cLightBlack: #1F1F1F;

$cboldGray: #707070;
$cLightGray: #B9B9B9;
$cThinGray: #C7C7C7;
$cGray: #8D8D8E;
$cRed: #C81422;
$cWineRed: #9C0B2E;
$cBeige: #EBE8E2;
$cLightBeige: #FAF7F2;
$cOrange: #FF7B34;

/* ===============================================
# フォントサイズ
=============================================== */
$fs-9: 0.9rem;
$fs-10: 1.0rem;
$fs-12: 1.2rem;
$fs-14: 1.4rem;
$fs-16: 1.6rem;
$fs-17: 1.7rem;
$fs-18: 1.8rem;
$fs-19: 1.9rem;
$fs-20: 2rem;
$fs-22: 2.2rem;
$fs-23: 2.3rem;
$fs-25: 2.5rem;
$fs-30: 3rem;
$fs-31: 3.1rem;
$fs-35: 3.5rem;
$fs-38: 3.8rem;
$fs-40: 4rem;
$fs-41: 4.1rem;
$fs-42: 4.2rem;
$fs-47: 4.7rem;
$fs-49: 4.9rem;
$fs-50: 5rem;
$fs-52_79: 5.276rem;
$fs-56: 5.6rem;
$fs-59: 5.9rem;
$fs-60: 6rem;
$fs-68_42: 6.842rem;
$fs-75: 7.5rem;
$fs-80: 8rem;
$fs-90: 9rem;


//font-family
$ff-termina: termina, sans-serif;
$ff-senobi: senobi-gothic, sans-serif;
$ff-yu-go: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$ff-oswald: 'Oswald', sans-serif;
$ff-mont: 'Montserrat', sans-serif;
$ff-noto: 'Noto Sans JP', sans-serif;





//font-weight
$fw-bold: bold;
$fw-mid: 500;
$fw-nor: 400;
$fw-lig: 300;

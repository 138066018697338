@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;


.p-form-return{
	margin: 0 auto;
	padding-top: 2.6rem;
	display: block;
	&__anchor{
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		color: $cBoldBlack;
	}
}
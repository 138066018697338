@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;



.p-mv{
	background-color: $cBeige;
	width: 100vw;
	min-height: 100vh;
	height: auto;
	position: relative;
	display: flex;
	overflow: hidden;
	@include mq(lg){
		flex-direction: column;
		align-items: center;
		padding-bottom: 2rem;
	}
	&-background{
		min-width: 100vw;
		height: 100%;
		position: absolute;
		@include z-index(mvBackground);
		&__image{
			object-position: left;
			height: 100%;
		}
	}
	&-textarea{
		padding-top: calc(100vh * calc(200 / 800));
		padding-left: calc(100vw * calc(45 / 1280));
		width: max-content;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		@include z-index(mvLeft);
		@include mq(lg){
			padding-top: calc(100vh * calc(124.2 / 800));
		}
		&-titlearea{
			&__title{
				width: max-content;
				font-family: $ff-senobi;
				font-size: calc(100vw * calc(68.42 / 1280));//$fs-68_42;
				font-weight: $fw-mid;
				color: $cMidBlack;
				@include l-height(calc(68.42 + 26),68.42);
				padding-bottom: 3.29rem;
				@include mq(lg){
					font-size: calc(100vw * calc(45 / 375));
					padding-bottom: 2.05rem;
				}
				&--red{
					color: $cWineRed;
				}
				&--back-red{
					color: $cWhite;
					position: relative;
					z-index: 1;
				}
				&--fs-small{
					font-size: calc(100vw * calc(52.79 / 1280));//$fs-52_79;
					@include mq(lg){
						font-size: calc(100vw * calc(35 / 375));
					}
				}
			}
		}
		&-detail{
			display: flex;
			align-items: center;
			padding-bottom: 3.3rem;
			@include mq(lg){
				flex-direction: column;
				margin-left: -2rem;
				padding-bottom: 1.76rem;
			}
			&-unit{
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: calc(100vw * calc(191.66 / 1280));
				height: calc(100vw * calc(91.48 / 1280));
				animation-duration: 0.5s;
				animation-iteration-count: 1;
				animation-fill-mode: both;
				opacity: 0;
				@include mq(lg){
					width: calc(100vw * calc(320 / 375));
					height: 5.421rem;
				}
				&::after{
					content: "";
					width: 100%;
					height: 100%;
					background-color: $cWhite;
					position: absolute;
					top: 0;
					z-index: 0;
					transform: skewX(-12deg);
					@include mq(lg){
						transform: skewX(-24deg);
					}	
				}
				&__item{
					position: relative;
					z-index: 1;
					color: $cBoldBlack;
					text-align: center;
					font-size: calc(100vw * calc(18 / 1280));//$fs-18;
					font-weight: $fw-bold;
					font-family: $ff-yu-go;
					color: $cBoldBlack;
					@include l-height(24 , 18);
					@include mq(lg){
						font-size: $fs-17;
					}
					& .--br-sl-none{
						@include mq(lg){
							display: none;
						}
					}
				}
				& .--red{
					font-size: calc(100vw * calc(22 / 1280));//$fs-22;
					font-weight: $fw-bold;
					color: $cRed;
					@include l-height(24 , 22);
					@include mq(lg){
						font-size: $fs-20;
					}
				}
			}
			& .top-down-change{
				flex-direction: column;
				@include mq(lg){
					flex-direction: column-reverse;
				}
			}
			& .--center{
				margin-left: 1.8rem;
				margin-right: 1.8rem;
				@include mq(lg){
					margin: 0;
					margin-top: 0.93rem;
					margin-bottom: 0.93rem;
				}
			}
			& .--left{
				animation-delay: 0s;
			}
			& .--center{
				animation-delay: 0.25s;
			}
			& .--right{
				animation-delay: 0.5s;
			}
		}
	}
	&-imagearea{
		position: absolute;
		@include z-index(mvRight);
		width: calc(100vw * calc(515 / 1280));
		height: max-content;
		transform: rotateZ(12deg);
		background-color: $cWhite;
		bottom: -10%;
		right: 4%;
		@include mq(lg){
			position: relative;
			transform: rotateZ(-5deg);
			transform-origin: top right;
			bottom: 0;
			right: 0;
			width: 120vw;
			height: 20rem;
		}
		&-container{
			position: relative;
			width: 100%;
			height: 120vh;
			top: 0;
			animation-duration: 0.5s;
			animation-iteration-count: 1;
			animation-fill-mode: both;
			opacity: 0;
			@include mq(lg){
				width: 100%;
				height: 100%;
			}
		}
		&-frame{
			position: absolute;
			bottom: 0;
			height: max-content;
			@include mq(lg){
				width: max-content;
			}
		}
		&__image{
			margin-top: -14rem;
			height: auto;
			width: 100%;
			position: relative;
			bottom: 0;
			@include mq(lg){
				height: 20rem;
				width: auto;
				transform-origin: bottom;
			}
		}
	}
}
.p-message{
	width: 100vw;
	height: max-content;
	padding-top: 20rem;
	padding-bottom: 14.96rem;
	position: relative;
	background-color: $cLightBeige;
	@include mq(lg){
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
	&__title{
		text-align: center;
		font-size: $fs-80;
		color: $cBlack;
		padding-bottom: 10rem;
		position: relative;
		@include z-index(text);
		@include mq(lg){
			font-size: $fs-50;
		}
		@include mq(sm){
			padding-bottom: 5.02rem;
		}
	}
	& .--title-sl{
		font-size: $fs-68_42;
		@include mq(lg){
			font-size: $fs-40;
		}
	}
	&__text{
		font-size: $fs-18;
		color: $cBoldBlack;
		font-weight: $fw-mid;
		font-family: $ff-yu-go;
		text-align: center;
		position: relative;
		@include z-index(text);
		@include l-spacing(100);
		@include l-height(45 , 18);
		@include mq(sm){
			font-size: $fs-16;
		}
		& .--br-sl-use{
			display: none;
			@include mq(sm){
				display: block;
			}
		}
	}
	& .--text-lg{
		font-weight: $fw-bold;
		font-size: $fs-20;
		@include mq(sm){
			font-size: $fs-18;
		}
	}
	&__background{
		width: 100vw;
		position: absolute;
		@include z-index(background);
		top:0;
		height: 100%;
		&__image{
			position: absolute;
		}
		& .--first-image{
			width: calc(100vw * calc(543.02 / 1280));//54.302rem;
			top: -6.22rem;
			left: 3.37rem;
			@include mq(lg){
				width: 37.5rem;
				top: auto;
				bottom: 0;
				left: 0;
			}
		}
		& .--seccond-image{
			width: calc(100vw * calc(516.61 / 1280));//51.661rem;
			right: 5.74rem;
			@include mq(lg){
				width: 37.5rem;
				top: 0;
				right: 0;
			}
		}
		&__text{
			color: $cBeige;
			font-size: calc(100vw * calc(160 / 1280));
			font-family: $ff-termina;
			text-align: center;
			transform: rotateZ(-3deg);
			opacity: 0.4;
			font-weight: $fw-mid;
			position: absolute;
			top: 54.35rem;
			@include l-spacing(100);
			left: calc(100vw * calc(65 / 1280));
			@include mq(lg){
				top: 43.57rem;
			}
		}
	}
}
.p-pitch{
	width: 100vw;
	padding-bottom: 5.03rem;
	position: relative;
	&__background{
		position: absolute;
		width: 100%;
		height: 100%;
		@include z-index(background);
		&__image{
			height: 100%;
		}
	}
	&__title{
		font-size: $fs-60;
		text-align: center;
		line-height: 1.5;
		padding-top: 5.13rem;
		padding-bottom: 5rem;
		position: relative;
		@include z-index(title);
		@include mq(lg){
			font-size: $fs-40;
			font-size: calc(100vw * calc(40 / 375));
		}
		& .--title-sl{
			font-size: $fs-52_79;
			@include mq(lg){
				font-size: calc(100vw * calc(35 / 375));
			}
		}
	}
	&__pitchbox{
		width: calc(100vw * calc(872.47 / 1280));
		margin: 0 auto;
		position: relative;
		@include z-index(text);
		@include mq(lg){
			width: calc(100vw * calc(330 / 375))
		}
	}
}
.p-work{
	width: 100vw;
	background-color: $cLightBeige;
	position: relative;
	display: flex;
	flex-direction: column;
	&-title{
		font-size: $fs-90;
		top: 8.18rem;
		left: calc(100vw * calc(107 / 1280));
		position: absolute;
		width: max-content;
		@include z-index(title);
		@include mq(lg){
			position: relative;
			top: 0;
			padding-top: 10rem;
			padding-bottom: 5rem;
			font-size: calc(100vw * calc(49 / 375));
			text-align: center;
			left: 50%;
			transform: translateX(-50%);
		}
		& .--br-sl-use{
			display: none;
			@include mq(lg){
				display: block;
			}
		}
		& .--title-sl{
			font-size: $fs-75;
			@include mq(lg){
				font-size: calc(100vw * calc(41 / 375));
			}
		}
	}
	&-above{
		width: 100%;
		display: flex;
		align-items: center;
		padding-top: 14.36rem;
		padding-bottom: 23.42rem;
		justify-content: flex-start;
		@include z-index(text);
		position: relative;
		@include mq(lg){
			flex-direction: column;
			padding-top: 0;
			padding-bottom: 5rem;
		}
		&-imagebox{
			width: calc(100vw * calc(602.39 / 1280));
			@include mq(lg){
				width: calc(100vw * calc(800 / 1280));
				padding-bottom: 5rem;
			}
			@include mq(sm){
				width: calc(100vw * calc(355 / 375));
				align-self: flex-start;
			}
			&__image{
				height: auto;
			}
		}
		&-textarea{
			width: calc(100vw * calc(539 / 1280));
			padding-left: calc(100vw * calc(31.6 / 1280));
			padding-top: 16.6rem;
			align-self: flex-start;
			@include mq(lg){
				width: calc(100vw * calc(800 / 1280));
				align-self: center;
				padding-top: 0;
			}
			@include mq(sm){
				width: calc(100vw * calc(355 / 375));
			}
			&__text{
				font-size: $fs-18;
				font-weight: $fw-mid;
				font-family: $ff-yu-go;
				color: $cBoldBlack;
				@include l-spacing(100);
				@include l-height(45 , 18);
				& .--text-fw{
					font-weight: $fw-bold;
				}
			}
		}
	}
	&-below__image{
		position: absolute;
		right: 0;
		bottom: -11.7rem;
		width: calc(100vw * calc(509.82 / 1280));
		@include z-index(text);
		@include mq(lg){
			position: relative;
			transform: translateX(50%);
			bottom:-10rem;
			margin: 0 auto;
			width: calc(100vw * calc(800 / 1280));
		}
		@include mq(sm){
			width: calc(100vw * calc(355 / 375));
			right: 0;
			transform: translateX(0);
		}
	}
	&-back-image-top{
		position: absolute;
		width: 33.455rem;
		top: 0;
		right: 10.7rem;
		@include z-index(background);
		@include mq(sm){
			width: 37.5rem;
			right: auto;
			left: -6.25rem;
		}
	}
	&-back-image-bottom{
		position: absolute;
		width: 43.5rem;
		bottom: 3.14rem;
		left: calc(100vw * calc(393.5 / 1280));
		@include z-index(background);
		@include mq(sm){
			width: 37.5rem;
			bottom: 10.15rem;
			left: 0;
		}
	}
}
.p-charm{
	width: 100vw;
	background-color: $cWhite;
	position: relative;
	padding-bottom: 10.28rem;
	&__title{
		font-size: $fs-90;
		padding-top: 14.7rem;
		padding-left: 10.7rem;
		position: relative;
		@include z-index(title);
		@include mq(lg){
			text-align: center;
			padding-left: 0;
			font-size: calc(100vw * calc(49 / 375));
			padding-top: 15rem;
			padding-bottom: 5rem;
		}
		& .--title-sl{
			font-size: $fs-75;
			@include mq(lg){
				font-size: calc(100vw * calc(41 / 375));
			}
		}
		& .--br-sl-use{
			display: none;
			@include mq(lg){
				display: block;
			}
		}
	}
	&-container{
		width: calc(100vw * calc(1066 / 1280));
		margin: 0 auto;
		padding-top: 5rem;
		@include mq(lg){
			padding-top: 0;
			width: calc(100vw * calc(800 / 1280));
		}
		@include mq(sm){
			width: calc(100vw * calc(335 / 375));
		}
	}
	&-unit{
		@include z-index(text);
		position: relative;
		width: 100%;
		display: flex;
		justify-content: space-between;
		@include mq(lg){
			flex-direction: column-reverse;
		}
		&__text{
			padding-top: 2.96rem;
			animation-delay: 0.5s;
			@include mq(sm){
				padding-top: 3.3rem;
			}
			&-num{
				width: 14.431rem;
				padding-bottom: 5.05rem;
				box-sizing: content-box;
				@include mq(sm){
					width: 10.255rem;
					height: 9.564rem;
					padding-bottom: 3.23rem;
				}
			}
			&-subtitle{
				font-size: $fs-25;
				font-family: $ff-yu-go;
				font-weight: $fw-bold;
				@include l-spacing(100);
				@include l-height(35 , 25);
				color: $cBoldBlack;
				padding-bottom: 5.05rem;
				@include mq(sm){
					font-size: $fs-23;
				}
			}
			&-textbox{
				width: calc(100vw * calc(485 / 1280));
				@include mq(lg){
					width: 100%;
				}
				&__text{
					font-size: $fs-16;
					font-weight: $fw-mid;
					font-family: $ff-yu-go;
					color: $cBlueBlack;
					@include l-height(33 , 16);
					@include l-spacing(100);
				}
			}
		}
		&-imagebox{
			width: calc(100% * calc(505.87 / 1066));//50.587rem;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mq(lg){
				width: 100%;
			}
			&__image{
				height: auto;
			}
		}
	}
	& .-unit-center{
		padding-top: 10.08rem;
		padding-bottom: 7.96rem;
		@include mq(lg){
			flex-direction: column;
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}
	& .-charm-background{
		position: absolute;
		@include z-index(background);
	}
	&-background-top-left{
		top: 1.45rem;
		left: 1.3rem;
		width: 41.355rem;
		@include mq(sm){
			width: 28.739rem;
			top: 10rem;
			left: 0;
		}
	}
	&-background-mid-right{
		bottom: 98.08rem;
		right: 0;
		width: 36.118rem;
		@include mq(lg){
			right: 50%;
			transform: translateX(50%);
			bottom: 130rem;
		}
		@include mq(sm){
			top: calc(100vw * calc(1530 / 375));
			bottom: auto;
		}
	}
	&-background-bottom{
		bottom: 31.31rem;
		left: 26.95rem;
		width: 52.688rem;
		@include mq(lg){
			left: 50%;
			transform: translateX(-50%);	
		}
		@include mq(sm){
			bottom: calc(100vw * calc(380 / 375));
			width: 38.65rem;
		}
	}
}
.p-voice{
	width: 100vw;
	background-color: $cLightBeige;
	position: relative;
	&-background-first{
		position: absolute;
		width: 8.691rem;
		left: calc(100vw * calc(804 / 1280));
		top: 4.56rem;
		@include mq(lg){
			top: 0.4rem;
			left: calc(100vw * calc(292.8 / 375));
		}
	}
	&-background-seccond{
		position: absolute;
		width: 8.691rem;
		left: calc(100vw * calc(378.7 / 1280));
		transform: rotateZ(-15deg);
		top: 18.86rem;
		@include mq(lg){
			top: 8.98rem;
			left: calc(100vw * calc(24.6 / 375));
		}
	}
	&-background-third{
		position: absolute;
		width: 8.691rem;
		right: 4rem;
		top: 21.13rem;
		@include mq(lg){
			display: none;
		}
	}
	&__title{
		font-size: $fs-90;
		color: $cMidBlack;
		text-align: center;
		padding-top: 10rem;
		@include mq(lg){
			font-size: $fs-56;
			padding-top: 5rem;
		}
		& .--title-sl{
			font-size: $fs-75;
			@include mq(lg){
				font-size: $fs-47;
			}
		}
	}
	&-container{
		width: calc(100vw * calc(1066 / 1280));
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		@include mq(lg){
			flex-direction: column;
			padding-top: 5rem;
		}
		@include mq(sm){
			width: calc(100vw * calc(335 / 375));
		}
	}
	&-unit{
		width: calc(100vw * calc(329 / 1280));
		margin-top: 13.16rem;
		background-color: $cWhite;
		min-height: 81.4rem;
		box-shadow: 0px 3px 6px rgba(141,141,142,0.16);
		@include mq(lg){
			width: 100%;
			margin: 0;
		}
		&-imagebox{
			width: 100%;
		}
		&__name{
			font-size: $fs-23;
			color: $cBoldBlack;
			font-family: $ff-yu-go;
			font-weight: $fw-bold;
			text-align: center;
			padding-top: 2.26rem;
			@include l-spacing(100);
			@include l-height(30 , 23);
		}
		&__position{
			color: $cWineRed;
			font-size: $fs-20;
			font-weight: $fw-bold;
			font-family: $ff-yu-go;
			text-align: center;
			@include l-spacing(100);
			@include l-height(30 , 20);
			padding-bottom: 3.01rem;
			& .--sl-none{
				@media (max-width:1080px){
					display: none;
				}
			}
			& .--br-sl-use{
				display: none;
				@include mq(lg){
					display: block;
				}
			}
			& .--br-sl-none-use{
				display: none;
				@media (max-width:1080px){
					display: block;
				}
				@include mq(lg){
					display: none;
				}
			}
		}
		&__text{
			padding-left: 3.3rem;
			padding-right: 3.3rem;
			padding-bottom: 2.03rem;
			color: $cBlueBlack;
			font-size: $fs-16;
			font-weight: $fw-mid;
			font-family: $ff-yu-go;
			@include l-spacing(100);
			@include l-height(33 , 16);

		}
	}
	& .--left{
		animation-delay: 0s;
	}
	& .--center{
		animation-delay: 0.25s;
	}
	& .--right{
		animation-delay: 0.5s;
	}
	& .--unit-center{
		@include mq(lg){
			margin-top: 5rem;
			margin-bottom: 5rem;
		}
	}
}
.p-recruit{
	width: 100vw;
	background-color: $cLightBeige;
	padding-bottom: 9.3rem;
	&__title{
		font-size: $fs-90;
		width: max-content;
		height: max-content;
		margin: 0 auto;
		padding-top: 10.6rem;
		position: relative;
		color: $cBoldBlack;
		@include z-index(title);
		@include mq(sm){
			font-size: $fs-59;
		}
	}
	& .--under-line{
		position: absolute;
		width: 28rem;
		height: 1.7rem;
		background-color: $cOrange;
		left: 50%;
		transform: translateX(-46.5%);
		margin-top: -2rem;
		@include z-index(background);
		@include mq(sm){
			width: 18.551rem;
			height: 1.136rem;
			margin-top: -1.5rem;
		}
	}
	&__sub-text{
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		color: $cBlueBlack;
		@include l-height(33 , 16);
		@include l-spacing(100);
		margin: 0 auto;
		padding-top: 3.71rem;
		padding-left: 3.5%;
		padding-bottom: 5.05rem;
		width: max-content;
		@include mq(sm){
			text-align: center;
			padding-top: 2.99rem;
			padding-bottom: 5rem;
		}
		& .--br-sl-use{
			display: none;
			@include mq(sm){
				display: block;
			}
		}
	}
	&-units-container{
		width: calc(100vw * calc(1066 / 1280));
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		flex-wrap: wrap;
		@include mq(lg){
			width: 55%;
			transform: scale(1.4);
			transform-origin: top;
			padding-bottom: 25vw;
		}
		@include mq(sm){
			width: 100%;
			justify-content: center;
			transform: scale(1);
			flex-direction: column;
			align-items: center;
			padding-bottom: 0;
		}
	}
	&-unit{
		width: calc(100vw * calc(335 / 1280));
		height: calc(100vw * calc(335 / 1280));
		background-color: $cWhite;
		border-radius: 100%;
		position: relative;
		display: flex;
		justify-content: center;
		box-shadow: 0px 3px 6px rgba(141,141,142,0.16);
		@include mq(sm){
			width: calc(100vw * calc(335 / 375));
			height: calc(100vw * calc(335 / 375));
		}
		&-imagebox{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			@include z-index(background);
		}
		& .--size-money{
			width: calc(100% * calc(142.59 / 335));
		}
		& .--size-incentive{
			width: calc(100% * calc(158.41 / 335));
		}
		& .--size-time{
			width: calc(100% * calc(214.86 / 335));
		}
		& .--size-train{
			width: calc(100% * calc(147.43 / 335));
		}
		& .--size-cheers{
			width: calc(100% * calc(167.44 / 335));
		}
		& .--size-dress{
			width: calc(100% * calc(198 / 335));
		}
		&-textbox{
			position: relative;
			@include z-index(text);
			text-align: center;
			&__text{
				color: $cBoldBlack;
				font-size: calc(100vw * calc(35 / 1280));
				font-weight: $fw-bold;
				font-family: $ff-yu-go;
				@include l-spacing(100);
				@include mq(sm){
					font-size: calc(100vw * calc(35 / 335));
				}
				& .--ff-oswald{
					font-family: $ff-oswald;
				}
				& .--fw-mid{
					font-weight: $fw-mid;
				}
				& .--fw-bold{
					font-weight: $fw-bold;
				}
				& .--fs-25{
					font-size: calc(100vw * calc(25 / 1280));
					@include mq(sm){
						font-size: calc(100vw * calc(25 / 335))
					}
				}
				& .--fs-30{
					font-size: calc(100vw * calc(30 / 1280));
					@include mq(sm){
						font-size: calc(100vw * calc(30 / 335))
					}
				}
				& .--fs-41{
					font-size: calc(100vw * calc(41 / 1280));
					@include mq(sm){
						font-size: calc(100vw * calc(41 / 335))
					}
				}
				& .--fs-58{
					font-size: calc(100vw * calc(58 / 1280));
					@include mq(sm){
						font-size: calc(100vw * calc(58 / 335))
					}
				}
			}
			& .--top-position-money{
				padding-top: calc(100vw * calc(80 / 1280));
				@include mq(sm){
					padding-top: calc(100vw * calc(50 / 375));
				}
			}
			& .--top-position-incentive{
				padding-top: calc(100vw * calc(99 / 1280));
				white-space: nowrap;
				@include mq(sm){
					padding-top: calc(100vw * calc(110 / 375));
				}
			}
			& .--top-position-time{
				padding-top: calc(100vw * calc(105 / 1280));
				@include l-height(57 , 41);
				@include mq(sm){
					padding-top: calc(100vw * calc(105 / 375));
				}
			}
			& .--top-position-train{
				padding-top: calc(100vw * calc(126 / 1280));
				@include l-height(49 , 35);
				@include mq(sm){
					padding-top: calc(100vw * calc(125 / 375));
				}
			}
			& .--top-position-cheers{
				padding-top: calc(100vw * calc(133 / 1280));
				@include l-height(42 , 35);
				@include mq(sm){
					padding-top: calc(100vw * calc(133 / 375));
				}
			}
			& .--top-position-dress{
				padding-top: calc(100vw * calc(129 / 1280));
				@include l-height(42 , 35);
				@include mq(sm){
					padding-top: calc(100vw * calc(129 / 375));
				}
			}
			& .--line-height-incentive{
				line-height: 1.2;
			}
		}
	}
	& .--left{
		animation-delay: 0s;
	}
	& .--center{
		animation-delay: 0.25s;
	}
	& .--right{
		animation-delay: 0.5s;
	}
	& .--above{
		margin-bottom: 5rem;
		@include mq(lg){
			margin-bottom: 3rem;
		}
	}
	& .--below{
		margin-bottom: 5rem;
		@include mq(lg){
			margin-bottom: 3rem;
		}
	}
	& .--bottom{
		margin-bottom: 0;
	}
}
.p-flow{
	width: 100vw;
	background-color: $cLightBeige;
	padding-bottom: 10rem;
	@include mq(lg){
		padding-bottom: 7rem;
	}
	&-titlebox{
		width: calc(100vw * calc(1066 / 1280));
		margin: 0 auto;
		display: flex;
		align-items: flex-end;
		padding-bottom: 5.02rem;
		@include mq(lg){
			flex-direction: column;
			align-items: flex-start;
		}
		&-imagebox{
			width: 28.705rem;
			@include mq(sm){
				width: 13.618rem;
			}
		}
		&__title{
			width: 100%;
			padding-left: 3.8rem;
			border-bottom: 1px solid $cWineRed;
			font-size: $fs-52_79;
			color: $cMidBlack;
			@include mq(lg){
				padding-left: 0;
				border-bottom: 0px;
				border-top: 1px solid $cWineRed;
				padding-top: 2.03rem;
				margin-top: 2.03rem;
			}
			@include mq(sm){
				font-size: $fs-30;
			}
			& .--title-sl{
				font-size: $fs-42;
				@include mq(sm){
					font-size: $fs-23;
				}
			}
		}
	}
	&-units-container{
		width: calc(100vw * calc(1066 / 1280));
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		@include mq(lg){
			flex-direction: column;
			align-items: center;
			padding-bottom: 7rem;
		}
		&-background{
			width: 100%;
			height: 1.5rem;
			background-color: $cWineRed;
			position: absolute;
			@include z-index(background);
			top: 50%;
			animation-duration: 1s;
			animation-iteration-count: 1;
			animation-fill-mode: both;
			transform: scaleX(0);
			transform-origin: left;
			@include mq(lg){
				height: 80%;
				width: 1.5rem;
				top: 1px;
				transform: scaleY(0);
				transform-origin: top;
			}
		}
	}
	&-unit{
		width: calc(100vw * calc(313 / 1280));
		background-color: $cWhite;
		box-shadow: 0px 3px 6px rgba(141,141,142,0.16);
		position: relative;
		@include z-index(text);
		@include mq(lg){
			width: calc(100vw * calc(313 / 561));
			margin-bottom: 3rem;
		}
		@include mq(sm){
			width: calc(100vw * calc(313 / 375));
		}
		&-imagebox{
			padding-top: 3.6rem;
		}
		& .--image-size-first{
			width: calc(100% * calc(174.65 / 313));
			margin-left: calc(100% * calc(77 / 313));
			padding-bottom: 1.8rem;
		}
		& .--image-size-seccond{
			width: calc(100% * calc(215.98 / 313));
			margin-left: calc(100% * calc(49 / 313));
			padding-bottom: 1.75rem;
		}
		& .--image-size-third{
			width: calc(100% * calc(222.23 / 313));
			margin-left: calc(100% * calc(45 / 313));
			padding-bottom: 1.75rem;
		}
		&-title{
			display: flex;
			align-items: center;
			padding-left: 2.5rem;
			padding-bottom: 2rem;
			&-imagebox{
				width: calc(100% * calc(59 / 313));
			}
			&__title{
				color: $cBoldBlack;
				font-size: calc(100vw * calc(25 / 1280));
				padding-left: 1.5rem;
				font-family: $ff-yu-go;
				font-weight: $fw-bold;
				@include l-spacing(100);
				@include mq(lg){
					font-size: $fs-25;
				}
			}
		}
		&__text{
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			font-size: $fs-16;
			color: $cBlueBlack;
			font-family: $ff-yu-go;
			padding-bottom: 3.29rem;
			@include l-spacing(100);
			@include l-height(25 , 16);
		}
	}
	& .--left{
		animation-delay: 0s;
	}
	& .--center{
		animation-delay: 0.25s;
	}
	& .--right{
		animation-delay: 0.5s;
	}
}
.p-entryarea{
	width: 100vw;
	background-color: $cWineRed;
	padding-bottom: 4.8rem;
	position: relative;
	&-background{
		position: absolute;
		height: 100%;
		&__image{
			height: 100%;
		}
	}
	&-titlebox{
		width: calc(100vw * calc(1066 / 1280));
		margin: 0 auto;
		padding-top: 7.1rem;
		padding-bottom: 3.08rem;
		text-align: center;
		&__title{
			font-size: calc(100vw * calc(58 / 1280));
			color: $cWhite;
			@include mq(lg){
				width: 100%;
				font-size: $fs-38;
			}
			@include l-height(70 , 58);
			& .-l-spacing-80{
				@include l-spacing(-80);
			}
			& .--title-sl{
				font-size: calc(100vw * calc(49 / 1280));
				@include mq(lg){
					font-size: $fs-31;
				}
			}
			& .--br-sl-use{
				display: none;
				@include mq(lg){
					display: block;
				}
			}
		}
	}
}
@use '../foundation/variables' as *;
@use '../foundation/mixin' as *;

.p-thanks{
	width: 100%;
	background-color: $cLightBeige;
	padding-bottom: 10rem;
	&-page-show{
		font-size: $fs-16;
		font-weight: $fw-mid;
		font-family: $ff-yu-go;
		@include l-spacing(100);
		color: $cBlueBlack;
		padding-top: 15.6rem;
		padding-left: calc(100vw * calc(107 / 1280));
		padding-bottom: 5.3rem;
		& .--br-sl-use{
			display: none;
			@include mq(sm){
				display: block;
				content: "";
				height: 1rem;
			};
		}
		@include mq(lg){
			padding-top: 8.9rem;
			padding-bottom: 10rem;
		}
		&__arrow{
			color: $cBoldBlack;
			padding-left: 1.07rem;
			padding-right: 1.07rem;
		}
	}
	&__title-en{
		font-size: $fs-35;
		font-weight: $fw-bold;
		font-family: $ff-oswald;
		@include l-spacing(100);
		@include l-height(33 , 35);
		color: $cWineRed;text-align: center;
		padding-bottom: 0.9rem;
	}
	&__title-ja{
		font-size: $fs-16;
		font-weight: $fw-bold;
		font-family: $ff-yu-go;
		@include l-spacing(100);
		@include l-height(33 , 16);
		text-align: center;
		padding-bottom: 5.05rem;
	}
	&-stepsbox{
		width: max-content;
		padding-right: 2.3rem;
		padding-left: 2.3rem;
		padding-top: 2.65rem;
		padding-bottom: 2.65rem;
		border-top: 1px solid $cGray;
		border-bottom: 1px solid $cGray;
		margin: 0 auto;
		margin-bottom: 5.15rem;
		font-family: $ff-yu-go;
		&__steps{
			font-size: $fs-16;
			font-weight: $fw-bold;
			@include l-spacing(100);
			& .--arrow{
				padding-right: 3rem;
				padding-left: 3rem;
			}
			& .--red{
				color: $cWineRed;
			}
		}
	}
	&-textbox{
		padding-top: 5.05rem;
		padding-bottom: 5rem;
		border-top: 1px solid $cGray;
		border-bottom: 1px solid $cGray;
		width: calc(100vw * calc(775.49 / 1280));
		margin: 0 auto;
		padding-left: 4.6rem;
		padding-right: 4.6rem;
		@include mq(lg){
			padding-left: 0;
			padding-right: 0;
			width: calc(100vw * calc(335 / 375));
		}
		&__text{
			font-size: $fs-16;
			font-weight: $fw-nor;
			font-family: $ff-yu-go;
			@include l-height(27 , 16);
		}
		&-list{
			padding-top: 1.6rem;
			padding-bottom: 3.15rem;
			list-style-type: disc;
			padding-left: 1.6rem;
		}
		&__red-text{
			font-size: $fs-20;
			font-weight: $fw-mid;
			font-family: $ff-yu-go;
			color: $cWineRed;
		}
	}
}